import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mayaCepDuplicateRoute } from 'basics/constants/routes.constants';
import { PAGINATION_PAGE_SIZE } from 'modules/maya/basics/constants/common.constant';
import { CepTemplateSelectorFiltersType } from 'modules/maya/components/CepTemplateSelectorForm/CepTemplateSelectorForm.types';
import useCepTemplateSelectorListConfig from 'modules/maya/components/CepTemplateSelectorList/CepTemplateSelectorList.config';
import useGetAllCepQuery from 'modules/maya/graphql/queries/getAllCeps';

type UseCepTemplateSelectorListPropsType = {
  cepTemplateSelectorFilters?: CepTemplateSelectorFiltersType;
};

const useCepTemplateSelectorList = ({ cepTemplateSelectorFilters }: UseCepTemplateSelectorListPropsType) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
  const navigate = useNavigate();

  const queryOptions = useMemo(
    () => ({
      limit: pageSize,
      offset: page * pageSize,
    }),
    [page, pageSize],
  );

  const { data: ceps, loading, count } = useGetAllCepQuery({
    ...cepTemplateSelectorFilters,
    isTemplate: true,
    getPopularity: true,
  }, queryOptions);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const cepFromTemplateCallback = async (cepId: string) => {
    navigate(`${mayaCepDuplicateRoute}?sourceId=${cepId}`);
  };

  const {
    cepTemplateListColumnConfig,
  } = useCepTemplateSelectorListConfig({ cepFromTemplateCallback });

  return {
    ceps,
    loading,
    cepTemplateListColumnConfig,
    onPageChange,
    onPageSizeChange,
    count,
  };
};

export default useCepTemplateSelectorList;
