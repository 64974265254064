import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import useCepFormGoalGroupSchema from './CepFormGoalGroup.schema';
import { CepFormGoalGroupConfigType } from './CepFormGoalGroup.types';
import { CepStatus } from 'modules/maya/basics/enums/maya.enums';
import { cepToForm } from 'modules/maya/basics/transformers/Cep.transformer';
import { CepStateType } from 'modules/maya/basics/types/maya.types';
import { goalStepFields } from 'modules/maya/groups/CepFormGoalGroup/CepFormGoalGroup.constants';
import cepState from 'modules/maya/states/cep.states';

const useCepFormGoalGroupConfig = (
  cepEngagement?: number,
  cepReach?: number,
) => {
  const { t } = useTranslation();
  const cepFormGoalGroupSchema = useCepFormGoalGroupSchema();

  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const defaultFormValue = cepToForm(cepStateValue.cep);

  const isCepApproved = cepStateValue.cep?.status === CepStatus.APPROVED;

  const cepFormGoalGroup: CepFormGoalGroupConfigType = {
    targetEngagementGoal: {
      label: t('maya_cep_step_goal_targetEngagementGoal_label'),
      name: goalStepFields.targetEngagementGoal,
      placeholder: t('maya_cep_step_goal_targetEngagementGoal_placeholder'),
      required: true,
      defaultValue: defaultFormValue.targetEngagementGoal || cepEngagement?.toString(),
      validation: cepFormGoalGroupSchema.targetEngagementGoal,
      disabled: isCepApproved,
      type: 'number',
    },
    npsGoal: {
      label: t('maya_cep_step_goal_npsGoal_label'),
      name: goalStepFields.npsGoal,
      placeholder: t('maya_cep_step_goal_npsGoal_placeholder'),
      validation: cepFormGoalGroupSchema.npsGoal,
      type: 'number',
      defaultValue: defaultFormValue.npsGoal,
      disabled: isCepApproved,
    },
    reachGoal: {
      label: t('maya_cep_step_goal_reachGoal_label'),
      name: goalStepFields.reachGoal,
      required: true,
      validation: cepFormGoalGroupSchema.reachGoal,
      type: 'number',
      defaultValue: defaultFormValue.reachGoal || cepReach,
      disabled: isCepApproved,
    },
  };

  return {
    cepFormGoalGroup,
  };
};

export default useCepFormGoalGroupConfig;
