import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Heading from 'modules/maya/components//Heading';
import CepForm from 'modules/maya/components/CepForm/CepForm';
import MayaLayout from 'modules/maya/components/MayaLayout';
import useCepTemplateCreate from 'modules/maya/routes/CepTemplateCreate/CepTemplateCreate.hook';

const CepTemplateCreate: FC = () => {
  const { t } = useTranslation();
  const {
    submitCallback,
  } = useCepTemplateCreate();

  return (
    <MayaLayout>
      <Heading>
        { t('maya_cep_tpl_form_title') }
      </Heading>

      <CepForm submitCallback={ submitCallback } isTemplate={ true } />
    </MayaLayout>
  );
};

export default CepTemplateCreate;
