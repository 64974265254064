import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormLayoutContainer } from 'components/FormLayout';
import useCepTemplateSelectorFilters from 'modules/maya/components/CepTemplateSelectorFilters/CepTemplateSelectorFilters.hook';
import { cepTemplatSelectorFilters } from 'modules/maya/components/CepTemplateSelectorFilters/CepTemplateSelectorFilters.style';
import { CepTemplateSelectorFiltersType } from 'modules/maya/components/CepTemplateSelectorForm/CepTemplateSelectorForm.types';
import FormContainer from 'modules/maya/components/FormContainer/FormContainer';
import Heading from 'modules/maya/components/Heading';
import Box from 'yoda-ui/components/Box';
import MultiSelectYoda from 'yoda-ui/components/Form/SelectYoda/MultiSelectYoda';

type CepTemplateSelectorFiltersPropsType = {
  setCepTemplateSelectorFilters: (value: CepTemplateSelectorFiltersType) => void;
};

const CepTemplateSelectorFilters: FC<CepTemplateSelectorFiltersPropsType> = ({ setCepTemplateSelectorFilters }) => {
  const { t } = useTranslation();

  const { providerFields,
    cepTemplateSelectorFiltersConfig,
    submitButtonConfig } = useCepTemplateSelectorFilters({ setCepTemplateSelectorFilters });

  return (
    <Box>

      <Heading>
        { t('maya_filter_by') }
      </Heading>

      <FormContainer providerFields={ providerFields } submitButtonConfig={ submitButtonConfig } >
        <FormLayoutContainer sx={ cepTemplatSelectorFilters }>
          <Box>
            <MultiSelectYoda { ...cepTemplateSelectorFiltersConfig.customerType } />
          </Box>
          <Box>
            <MultiSelectYoda { ...cepTemplateSelectorFiltersConfig.specialty } />
          </Box>
          <Box>
            <MultiSelectYoda { ...cepTemplateSelectorFiltersConfig.channel } />
          </Box>
        </FormLayoutContainer>

      </FormContainer>
    </Box>

  );
};

export default CepTemplateSelectorFilters;
