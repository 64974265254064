import { SyntheticEvent, useContext } from 'react';
import { SelectOption } from 'basics/options/options.types';
import { CurrentUser } from 'basics/types/user.types';
import { CurrentUserStateContext } from 'contexts/user';
import { useCountrySelectorConfig } from 'modules/maya/components/CountrySelector/CountrySelector.config';
import { useCreateOrUpdateMayaUserDataMutation } from 'modules/maya/graphql/mutations/createOrUpdateMayaUserData';

export const useCountrySelector = (user?: CurrentUser) => {
  const { setCurrentUserData } = useContext(CurrentUserStateContext);
  const country = user?.country || null;
  const { countrySelectorConfig } = useCountrySelectorConfig(country);
  const [createOrUpdateMayaUserData] = useCreateOrUpdateMayaUserDataMutation();

  const handleCountryChange = (event: SyntheticEvent<Element, Event>, value: SelectOption) => {
    if (value?.id) {
      const countryValue = value.id.toString().toUpperCase() === 'AC' ? null : value.id.toString().toUpperCase();
      createOrUpdateMayaUserData({ country: countryValue });

      setCurrentUserData((prevState) => {
        return {
          ...prevState,
          currentUser: {
            ...prevState.currentUser,
            country: countryValue,
          } as CurrentUser,
        };
      });
    }
  };

  return {
    countrySelectorConfig,
    handleCountryChange,
  };
};
