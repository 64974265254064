import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import { eventsRoute, mayaRoute } from 'basics/constants/routes.constants';
import { getConfigVar } from 'config/config';
import { useCurrentUserState } from 'contexts/user';

const useHomePage = () => {
  const { user } = useCurrentUserState();
  const navigate = useNavigate();
  const flags = useFlags();

  const handleNavigateToMaya = () => {
    if (flags.mayaBiogenlinc5902EnableMayaSplitButton) {
      window.open(getConfigVar('REACT_APP_MAYA_URL'), '_self');
    } else {
      navigate(mayaRoute);
    }
  };

  const handleNavigateToEvents = () => {
    navigate(eventsRoute);
  };

  return {
    handleNavigateToMaya,
    handleNavigateToEvents,
    user,
  };
};

export default useHomePage;
