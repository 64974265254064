import { FC } from 'react';
import useGoalStep from './GoalStep.hook';
import CepFormGoalGroup from 'modules/maya/groups/CepFormGoalGroup';
import Box from 'yoda-ui/components/Box';

const GoalStep: FC = () => {
  useGoalStep();
  return (
    <Box>
      <CepFormGoalGroup />
    </Box>
  );
};

export default GoalStep;
