import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from '../../../../basics/constants/common.constants';
import { VeevaVaultDocument, VeevaVaultDocumentFilter, VeevaVaultDocumentLimit } from '../../../../generated/maya.types';
import { extractApolloErrorDetails } from '../../../../services/graphql/extractApolloErrorDetails';
import Logger from '../../../../services/logger';

const GET_ALL_VEEVA_VAULT_DOCUMENTS_LAZY = gql`
    query getAllVeevaVaultDocumentLazy($limit: VeevaVaultDocumentLimit, $filters: VeevaVaultDocumentFilter) {
        getAllVeevaVaultDocument(limit: $limit, filters: $filters) {
            id,
            name,
            documentNumber,
            status,
        }
    }
`;

type UseGetVeevaVaultDocumentLazy = (limit: VeevaVaultDocumentLimit, filters: VeevaVaultDocumentFilter) => {
  getAllVeevaVaultDocumentLazy: Function;
  loading: boolean;
  error?: ApolloError;
  data: VeevaVaultDocument[];
};

type ResultType = {
  getAllVeevaVaultDocument: VeevaVaultDocument[];
};

const useGetAllVeevaVaultDocumentLazy: UseGetVeevaVaultDocumentLazy = (
  limit: VeevaVaultDocumentLimit,
  filters: VeevaVaultDocumentFilter,
) => {
  const [getAllVeevaVaultDocumentLazy, { data, loading, error, called }] = useLazyQuery<ResultType>(GET_ALL_VEEVA_VAULT_DOCUMENTS_LAZY, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    variables: {
      limit,
      filters,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getAllVeevaVaultDocumentLazy.ts - useGetAllVeevaVaultDocumentLazy',
    });
  }

  return {
    getAllVeevaVaultDocumentLazy,
    data: data?.getAllVeevaVaultDocument || [],
    loading,
    error,
    called,
  };
};
export default useGetAllVeevaVaultDocumentLazy;
