import { FC } from 'react';
import useConfigurationStep from './ConfigurationStep.hook';
import { CepConfigurationStep } from 'modules/maya/components/CepForm/Steps/ConfigurationStep/ConfigurationStep.types';
import CepFormConfigurationGroup from 'modules/maya/groups/CepFormConfigurationGroup';
import Box from 'yoda-ui/components/Box';

const ConfigurationStep: FC<CepConfigurationStep> = ({ isTemplate }) => {
  useConfigurationStep();
  return (
    <Box>
      <CepFormConfigurationGroup isTemplate={ isTemplate }/>
    </Box>
  );
};

export default ConfigurationStep;
