import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useCustomerInitiativeList from './CustomerInitiativeList.hook';
import GridList from 'modules/maya/components/GridList/GridList';
import MayaLayout from 'modules/maya/components/MayaLayout';
import ConfirmationDialog from 'yoda-ui/components/Dialogs/ConfirmationDialog';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import Loader from 'yoda-ui/components/Loader';
import { useYodaCreateForm, YodaFormProvider } from 'yoda-ui/yodaForm';
import { YodaColors } from 'yoda-ui/yodaTheme';

const CustomerInitiativeList: FC = () => {
  const { providerFields } = useYodaCreateForm();
  const { t } = useTranslation();
  const {
    cis,
    ciTitleFieldConfig,
    customerInitiativeListColumnConfig,
    debouncedHandleChangeCiTitle,
    deleteCiCallback,
    handleClick,
    loading,
    menuCustomerInitiativeLabel,
    newElementButton,
    onPageChange,
    onPageSizeChange,
    open,
    removeCiLoading,
    rowCountState,
    rowId,
    setOpen,
  } = useCustomerInitiativeList();

  return (
    <MayaLayout>
      <GridList
        getRowId={ (row) => row._id }
        onCellClick={ handleClick }
        loading={ loading }
        title={ menuCustomerInitiativeLabel }
        columnConfig={ customerInitiativeListColumnConfig }
        rows={ cis }
        onPageChange={ onPageChange }
        onPageSizeChange={ onPageSizeChange }
        newElementButton={ newElementButton }
        rowCountState={ rowCountState }
        disableColumnMenu={ true }
        customFilters={
          (
            <YodaFormProvider { ...providerFields }>
              <Box>
                <TextYoda
                  onChange={ debouncedHandleChangeCiTitle }
                  { ...ciTitleFieldConfig } />
              </Box>
            </YodaFormProvider>
          )
        }
      />
      {
        open && (
          <ConfirmationDialog
            dialogTitle={ t('maya_ci_modal_remove') }
            onConfirm={
              () => {
                deleteCiCallback(rowId);
              }
            }
            onConfirmLabel={ removeCiLoading ? <Loader center size={ 1 } color={ YodaColors.inherit } /> : t('actions_confirm_label') }
            onDismiss={ () => setOpen(false) }
            onDismissLabel={ t('actions_cancel_label') }
          >
          </ConfirmationDialog>
        )
      }
    </MayaLayout>
  );
};

export default CustomerInitiativeList;
