import { ApolloError, gql, useQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { TargetFilterInput } from 'generated/maya.types';
import { targetsFacetsFragment } from 'modules/maya/graphql/fragments/targetList.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_TARGETS_FACETS = gql`
query getTargetsFacets($filters: TargetFilterInput!) {
  getTargetsFacets(filters: $filters) {
    ${targetsFacetsFragment}
  }
}
`;

type ResultType = {
  getTargetsFacets: {
    types: string[];
    country: string[];
    businessUnits: string[];
    specialties: string[];
    smaSegments: string[];
    biosSegments: string[];
    imsSegments: string[];
  };
};

type UseGetTargetsFacets = (filters: TargetFilterInput) => {
  loading: boolean;
  error?: ApolloError;
  targetsFacets: unknown;
};

const useGetTargetsFacets: UseGetTargetsFacets = (filters) => {
  const { data, loading, error } = useQuery<ResultType>(GET_TARGETS_FACETS, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    variables: {
      filters,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'countCustomerEngagementPlannings.ts - useCountCustomerEngagementPlanningsQuery',
    });
  }

  return {
    targetsFacets: data?.getTargetsFacets,
    loading,
    error,
  };
};

export default useGetTargetsFacets;
