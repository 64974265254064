import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Heading from 'modules/maya/components//Heading';
import CepForm from 'modules/maya/components/CepForm/CepForm';
import MayaLayout from 'modules/maya/components/MayaLayout';
import useCepTemplateEdit from 'modules/maya/routes/CepTemplateEdit/CepTemplateEdit.hook';

const CepTemplateEdit: FC = () => {
  const { t } = useTranslation();
  const {
    submitCallback,
  } = useCepTemplateEdit();

  return (
    <MayaLayout>
      <Heading>
        { t('maya_cep_tpl_edit_form_title') }
      </Heading>

      <CepForm submitCallback={ submitCallback } isTemplate={ true }/>
    </MayaLayout>
  );
};

export default CepTemplateEdit;
