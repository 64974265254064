import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FileExtension } from 'basics/types/files.types';
import { UseFormDropZoneStateSource } from 'components/QuickForm/FormsBucket/FormDropZone/FormDropZone.enums';
import { AssetTarget, AssetType } from 'generated/maya.types';
import useAopImportSchema from 'modules/maya/routes/ImportExport/AopImport/AopImport.schema';
import { UploadAopImportProps } from 'modules/maya/routes/ImportExport/AopImport/AopImport.types';
import { Icons } from 'yoda-ui/components/Icons/Icon';

type UploadAopImportConfig = Omit<UploadAopImportProps, 'handleFilesExternal' | 'handleRemoveItemAsync'>;

const useAopImportConfig = () => {
  const { t } = useTranslation();

  const aopImportSchema = useAopImportSchema();

  const uploadAopImportConfig: UploadAopImportConfig = useMemo(
    () => ({
      name: 'AopImport',
      formConfig: {
        btnLabel: t('maya_aop_import_upload_btnlabel'),
        btnIcon: Icons.upload,
        maxFileSize: 2048000,
        maxFilesNumber: 0,
        extensionsAllowed: [FileExtension.csv],
        message: t('maya_aop_import_upload_message'),
        label: '',
        name: 'AopImport',
        assetTarget: AssetTarget.aopImportCsv,
        assetType: AssetType.csv,
        assetsArePublic: true,
        stateSource: UseFormDropZoneStateSource.EXTERNAL,
      },
      defaultValue: [],
      validation: aopImportSchema.aopImport,
    }), [t, aopImportSchema],
  );

  return {
    uploadAopImportConfig,
  };
};

export default useAopImportConfig;
