import { gql, MutationResult, useMutation } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { FileDataInput } from 'generated/maya.types';
import { MutationReturnType } from 'modules/maya/basics/types/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export type UploadAopResponse = {
  importAopFromCSV: number;
};

export type UseImportAopFromCSVMutation = [
  (
    files: File,
    filesDataInput: FileDataInput,
  ) => Promise<MutationReturnType<number>>,
  MutationResult<UploadAopResponse>,
];

export const importAopFromCSVMutationCall = gql`
mutation importAopFromCSV($file: Upload!, $fileDataInput: FileDataInput!, $sendToVeeva: Boolean! ) {
    importAopFromCSV(file: $file, fileDataInput: $fileDataInput, sendToVeeva: $sendToVeeva)
}`;

export const useImportAopFromCSVMutation = (): UseImportAopFromCSVMutation => {
  const [importAopFromCSVMutation, state] = useMutation<UploadAopResponse>(importAopFromCSVMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });
  const flags = useFlags();

  const executeMutation = useCallback(async (file, fileDataInput) => {
    try {
      const { data } = await importAopFromCSVMutation({
        variables: { file, fileDataInput, sendToVeeva: flags.mayaEnableVeevaBiogenlinc1816 },
      });

      if (!data?.importAopFromCSV) {
        throw new Error('AOP Import Failed - 0 line imported');
      }

      return { data: data?.importAopFromCSV || 0, error: null };
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'importAop.ts - useImportAopFromCSVMutation',
        });

        return { data: null, error: customContext.message as string };
      }

      return { data: null, error: null };
    }
  }, [importAopFromCSVMutation, flags.mayaEnableVeevaBiogenlinc1816]);

  return [executeMutation, state];
};
