import { AlertColor } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { VeevaSyncStatus } from 'modules/maya/basics/enums/maya.enums';
import useVeevaStatusBar from 'modules/maya/components/VeevaStatusBar/VeevaStatusBar.hook';
import VeevaStatusBarProps from 'modules/maya/components/VeevaStatusBar/VeevaStatusBar.types';
import Alert from 'yoda-ui/components/Alert';
import Box from 'yoda-ui/components/Box';
import Collapse from 'yoda-ui/components/Collapse';
import CollapseIcon from 'yoda-ui/components/Icons/CollapseIcon';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const VeevaStatusBar: FC<VeevaStatusBarProps> = ({
  lastVeevaSync = null,
  lastVeevaSyncErrors = null,
  veevaSyncStatus = null,
}) => {
  const { t } = useTranslation();
  const {
    status,
    alertStatus,
    lastSyncDate,
    open,
    setOpen,
  } = useVeevaStatusBar(lastVeevaSync, veevaSyncStatus || VeevaSyncStatus.PENDING);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
      {
        veevaSyncStatus === VeevaSyncStatus.SYNC
          ? (
            <Alert severity={ alertStatus as AlertColor }>
              { `${t('maya_veeva_sync_status_header_label')}: ${status}` }
              { lastVeevaSync ? ` - ${t('maya_veeva_sync_status_last_synchronization_date_label')} : ${lastSyncDate} ` : null }
            </Alert>
          )
          : (
            <Alert severity={ alertStatus as AlertColor } action={
              <CollapseIcon
                sx={ { marginTop: -YodaSpacing.xxxSmall } }
                expand={ open }
                onClick={ handleClick }
                aria-expanded={ open }
                aria-label="show more"
                data-testid="collapse-icon"
              />
            }>
              { `${t('maya_veeva_sync_status_header_label')}: ${status}` }
              { lastVeevaSync ? ` - ${t('maya_veeva_sync_status_last_synchronization_date_label')} : ${lastSyncDate} ` : null }
              <Collapse
                in={ open }
                timeout="auto"
              >
                <Box padding={ YodaSpacing.xxxSmall }>
                  { lastVeevaSyncErrors ? lastVeevaSyncErrors.map((errors) => <p key={ errors }>- { errors } </p>) : null }
                </Box>
              </Collapse>
            </Alert>
          )
      }
    </Box>
  );
};

export default VeevaStatusBar;
