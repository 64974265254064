import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

import { BiogenProduct } from 'basics/enums/biogen.enums';
import { VeevaSyncStatusBadgeMap } from 'modules/maya/basics/constants/common.constant';
import { CiStatus, VeevaSyncStatus } from 'modules/maya/basics/enums/maya.enums';
import GridListAction from 'modules/maya/components/GridListAction/GridListAction';
import Tag from 'yoda-ui/components/Tags/Tag';
import { YodaColors, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

const useCustomerInitiativeListConfig = (
  editCiCallback: (ciId: string) => void,
  duplicateCiCallback: (ciId: string) => void,
  addChildAction: (ciId: string) => void,
  openModalCallback: (ciId: string) => void,
  syncCiCallback: (ciId: string) => void,
  approveCiCallback: (ciId: string) => void,
) => {
  const { t } = useTranslation();
  const flags = useFlags();

  const CiStatusBadgeMap = {
    [CiStatus.DRAFT as string]: {
      label: t('maya_status_draft'),
      bgColor: YodaColors.errorLight,
      color: YodaColors.error,
    },
    [CiStatus.ACTIVE as string]: {
      label: t('maya_status_active'),
      bgColor: YodaColors.greenSuccessLight,
      color: YodaColors.greenSuccessDark,
    },
  };

  const ciTitleFieldConfig = {
    label: t('maya_ci_form_ci_title_label'),
    name: 'name',
    placeholder: t('maya_ci_form_ci_title_placeholder'),
    defaultValue: '',
    required: false,
  };

  const customerInitiativeListColumnConfig: GridColDef[] = [
    {
      field: 'veevaSyncStatus',
      headerName: t('maya_veeva_sync_status_header_label'),
      flex: 0.2,
      renderCell: (cellValues: GridRenderCellParams) => {
        const badge = VeevaSyncStatusBadgeMap[cellValues.row.veevaSyncStatus || VeevaSyncStatus.PENDING];
        return badge && (
          <Tag
            fitContent
            bgcolor={ badge.bgColor }
            color={ badge.color }
            fontWeight={ YodaFontWeight.medium }
            paddingY={ YodaSpacing.xxxxSmall }
            paddingX={ YodaSpacing.xSmall }
            width={ 'auto' }
            height={ '20px' }
            borderRadius={ '1rem' }
          >
            { badge.label }
          </Tag>
        );
      },
      sortable: false,
    },
    {
      field: 'title',
      headerName: t('maya_customer_initiative_list_initiative_name_header_label'),
      flex: 0.4,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'product',
      headerName: t('maya_customer_initiative_list_product_header_label'),
      valueGetter: (params: GridValueFormatterParams) => {
        const productIndex = Object.values(BiogenProduct).indexOf(params.value as BiogenProduct);
        return Object.keys(BiogenProduct)[productIndex] || '';
      },
      flex: 0.2,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.1,
      renderCell: (cellValues: GridRenderCellParams) => {
        const badge = CiStatusBadgeMap[cellValues.row.status];
        return badge && (
          <Tag
            fitContent
            bgcolor={ badge.bgColor }
            color={ badge.color }
            fontWeight={ YodaFontWeight.medium }
            paddingY={ YodaSpacing.xxxxSmall }
            paddingX={ YodaSpacing.xSmall }
            width={ 'auto' }
            height={ '20px' }
            borderRadius={ '1rem' }
          >
            { badge.label }
          </Tag>
        );
      },
      sortable: false,
    },
    {
      field: 'action',
      headerName: '',
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params: GridRenderCellParams) => {
        const actions = [
          {
            label: t('actions_edit_label'),
            callback: () => { editCiCallback(params.row._id); },
          },
          {
            label: t('actions_duplicate_label'),
            callback: () => { duplicateCiCallback(params.row._id); },
          },
          {
            label: t('maya_customer_initiative_list_add_child_cep'),
            callback: () => {
              addChildAction(params.row._id);
            },
          },
        ];

        if (flags.mayaBiogenlinc3810EnableCiDeleting) {
          actions.splice(2, 0, {
            label: t('maya_action_delete_ci_label'),
            callback: () => {
              openModalCallback(params.row._id);
            },
          });
        }

        if (flags.mayaBiogenlinc2489VeevaSyncButtons) {
          actions.push({
            label: t('maya_action_sync_ci_label'),
            callback: () => {
              syncCiCallback(params.row._id);
            },
          });
        }

        if (flags.mayaBiogenlinc5411DisplayActivateCiCta) {
          actions.push({
            label: t('maya_action_activate_ci_label'),
            callback: () => {
              approveCiCallback(params.row._id);
            },
          });
        }

        return (
          <GridListAction
            actions={ actions }
          />
        );
      },
      align: 'right',
      headerAlign: 'right',
      flex: 0.1,
      sortable: false,
    },
  ];

  return { customerInitiativeListColumnConfig, ciTitleFieldConfig };
};

export default useCustomerInitiativeListConfig;
