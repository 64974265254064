import { cloneDeep } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { mayaCepListRoute } from 'basics/constants/routes.constants';
import { warningToast } from 'basics/utils/toast';
import { CustomerEngagementPlanning } from 'generated/maya.types';
import { CepStatus, VeevaSyncStatus } from 'modules/maya/basics/enums/maya.enums';
import { CepStateType } from 'modules/maya/basics/types/maya.types';
import { CepFormSubmitCallbackType } from 'modules/maya/components/CepForm/CepForm.types';
import { useDuplicateCepMutation } from 'modules/maya/graphql/mutations/duplicateCep';
import { useUpdateCepMutation } from 'modules/maya/graphql/mutations/updateCep';
import useGetCepByIdQuery from 'modules/maya/graphql/queries/getCepById';
import cepState from 'modules/maya/states/cep.states';

const useCepDuplicate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [duplicateCustomerEngagementPlanning] = useDuplicateCepMutation();
  const [updateCustomerEngagementPlanning] = useUpdateCepMutation();

  const customerEngagementPlanningStateValue = useSnapshot(cepState) as CepStateType;

  const urlParams = new URLSearchParams(window.location.search);
  const cepId = urlParams.get('sourceId') as string;

  const { data: customerEngagementPlanning, loading } = useGetCepByIdQuery(cepId || null);

  const prefix = customerEngagementPlanning?.customerInitiative.isTemplate ? 'From' : 'Copy';

  useEffect(() => {
    if (!loading) {
      if (customerEngagementPlanning) {
        const parentCi = customerEngagementPlanning.customerInitiative.isTemplate
          ? undefined : customerEngagementPlanning.customerInitiative;
        const clonedCep: Partial<CustomerEngagementPlanning> = cloneDeep(customerEngagementPlanning);

        clonedCep._id = '';
        clonedCep.customerInitiative = parentCi;
        clonedCep.dateEnd = null;
        clonedCep.dateStart = null;
        clonedCep.lastVeevaSync = null;
        clonedCep.lastVeevaSyncError = null;
        clonedCep.status = CepStatus.DRAFT;
        clonedCep.title = `${prefix} - ${clonedCep.title}`;
        clonedCep.updated = undefined;
        clonedCep.veevaSyncStatus = VeevaSyncStatus.PENDING;

        cepState.cep = clonedCep;
      } else {
        warningToast(t('common.wording.error404'));
        navigate(mayaCepListRoute);
      }
    }
  }, [customerEngagementPlanning, loading, navigate, prefix, t]);

  useEffect(() => {
    return function cleanup() {
      cepState.cep = {};
      cepState.tactics = [];
      cepState.targetList = [];
    };
  }, []);

  const submitCallback: CepFormSubmitCallbackType = async (cep) => {
    if (customerEngagementPlanningStateValue.cep?._id) {
      const customerEngagementPlanningData = await updateCustomerEngagementPlanning(
        customerEngagementPlanningStateValue.cep._id,
        cep,
        true,
      );

      return customerEngagementPlanningData;
    }

    const customerEngagementPlanningData = await duplicateCustomerEngagementPlanning(
      urlParams.get('sourceId') as string,
      { ...cep, sourceId: urlParams.get('sourceId') },
    );

    return { ...customerEngagementPlanningData, data: customerEngagementPlanningData.data?.cep || null };
  };

  return {
    submitCallback,
    customerEngagementPlanningStateValue,
  };
};

export default useCepDuplicate;
