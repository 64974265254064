import { CepSteps } from 'modules/maya/basics/enums/maya.enums';
import { useYodaForm } from 'yoda-ui/yodaForm';

const useConfigurationStep = () => {
  const { useWatchStep } = useYodaForm();
  const stepState = useWatchStep(CepSteps.configurationStep);

  return {
    stepIsValid: stepState ? stepState.isValid : false,
  };
};

export default useConfigurationStep;
