import { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { CepStateType } from 'modules/maya/basics/types/maya.types';
import useGetCepByIdQuery from 'modules/maya/graphql/queries/getCepById';
import cepState from 'modules/maya/states/cep.states';

const useCepGoalView = () => {
  const cepStateValue = useSnapshot(cepState) as CepStateType;

  const cepId = cepStateValue.cep?._id;

  const [loading, setLoading] = useState(true);

  const { data, loading: cepLoading } = useGetCepByIdQuery(cepId || null);

  useEffect(() => {
    if (data) {
      setLoading(cepLoading);
    }
  }, [cepLoading, data, setLoading]);

  return {
    cep: data,
    loading,
  };
};

export default useCepGoalView;
