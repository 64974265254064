import { t } from 'i18next';
import { VeevaSyncStatus } from 'modules/maya/basics/enums/maya.enums';
import { YodaColors } from 'yoda-ui/yodaTheme';

export const PAGINATION_PAGE_SIZE = 10;
export const PAGINATION_PAGE_SIZE_STEPS = [10, 20, 50];
export const KEY_MESSAGES_STATUS = 'true';
export const CAMPAIGN_YEAR = 2024;

export const VeevaSyncStatusBadgeMap = {
  [VeevaSyncStatus.ERROR as string]: {
    label: t('maya_veeva_sync_status_error'),
    bgColor: YodaColors.errorLight,
    color: YodaColors.error,
  },
  [VeevaSyncStatus.SYNC as string]: {
    label: t('maya_veeva_sync_status_synchronized'),
    bgColor: YodaColors.greenSuccessLight,
    color: YodaColors.greenSuccessDark,
  },
  [VeevaSyncStatus.PENDING as string]: {
    label: t('maya_veeva_sync_status_pending'),
    bgColor: YodaColors.warningLight,
    color: YodaColors.warning,
  },
};
