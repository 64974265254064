import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { SelectOption } from 'basics/options/options.types';
import { TargetFilter, TargetsFacets } from 'generated/maya.types';
import { BooleanOptionsValue, CepStatus } from 'modules/maya/basics/enums/maya.enums';
import booleanOptions from 'modules/maya/basics/options/boolean.options';
import channelAffinityFilterValueOptions from 'modules/maya/basics/options/targetsAffinity.options';
import useTargetsFiltersOptions from 'modules/maya/basics/options/targetsFilters.options';
import { CepStateType } from 'modules/maya/basics/types/maya.types';
import useDynamicTargetListSchema from 'modules/maya/components/DynamicTargetListForm/DynamicTargetListForm.schema';
import { DynamicTargetListFormConfigType } from 'modules/maya/components/DynamicTargetListForm/DynamicTargetListForm.types';
import cepState from 'modules/maya/states/cep.states';

const useDynamicTargetListConfig = (
  targetsFacets: TargetsFacets,
  filteredTargetsFacets: TargetsFacets,
  targetFilters: TargetFilter,
  setCountry: Dispatch<SetStateAction<string | null>>,
  setBusinessUnit: Dispatch<SetStateAction<string | null>>,
) => {
  const { t } = useTranslation();

  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const isCepApproved = cepStateValue.cep?.status === CepStatus.APPROVED;

  const dynamicTargetListSchema = useDynamicTargetListSchema();
  const targetFiltersOptions = useTargetsFiltersOptions(targetsFacets);
  const filteredTargetsFacetsOption = useTargetsFiltersOptions(filteredTargetsFacets);

  const dynamicTargetListConfig: DynamicTargetListFormConfigType = {
    country: {
      label: t('maya_dynamic_target_list_form_country_label'),
      name: 'country',
      placeholder: t('maya_dynamic_target_list_form_country_placeholder'),
      required: true,
      options: targetFiltersOptions.countries || [],
      defaultValue: targetFilters?.countries ? targetFilters?.countries[0] : null,
      validation: dynamicTargetListSchema.country,
      onChange: ((_: SyntheticEvent, value: SelectOption) => setCountry(value.id as string)),
      disabled: true,
    },
    type: {
      label: t('maya_dynamic_target_list_form_type_label'),
      name: 'type',
      placeholder: t('maya_dynamic_target_list_form_type_placeholder'),
      options: filteredTargetsFacetsOption.types || [],
      required: false,
      defaultValue: targetFilters?.types ? targetFilters?.types : [],
      disabled: isCepApproved || !filteredTargetsFacetsOption.types?.length,
    },
    businessUnit: {
      label: t('maya_dynamic_target_list_form_businessUnit_label'),
      name: 'businessUnit',
      placeholder: t('maya_dynamic_target_list_form_businessUnit_placeholder'),
      options: targetFiltersOptions.businessUnits || [],
      required: true,
      defaultValue: targetFilters?.businessUnits ? targetFilters?.businessUnits[0] : null,
      validation: dynamicTargetListSchema.businessUnit,
      onChange: ((_: SyntheticEvent, value: SelectOption) => setBusinessUnit(value.id as string)),
      disabled: isCepApproved,
    },
    specialty: {
      label: t('maya_dynamic_target_list_form_specialty_label'),
      name: 'specialty',
      placeholder: t('maya_dynamic_target_list_form_specialty_placeholder'),
      options: filteredTargetsFacetsOption.specialties || [],
      required: false,
      defaultValue: targetFilters?.specialties ? targetFilters?.specialties : [],
      disabled: isCepApproved || !filteredTargetsFacetsOption.specialties?.length,
    },
    smaSegments: {
      label: t('maya_dynamic_target_list_form_smaSegments_label'),
      name: 'smaSegments',
      placeholder: t('maya_dynamic_target_list_form_smaSegments_placeholder'),
      options: filteredTargetsFacetsOption.smaSegments || [],
      required: false,
      defaultValue: targetFilters?.smaSegments || [],
      disabled: isCepApproved || !filteredTargetsFacetsOption.smaSegments?.length,
    },
    biosSegments: {
      label: t('maya_dynamic_target_list_form_biosSegments_label'),
      name: 'biosSegments',
      placeholder: t('maya_dynamic_target_list_form_biosSegments_placeholder'),
      options: filteredTargetsFacetsOption.biosSegments || [],
      required: false,
      defaultValue: targetFilters?.biosSegments || [],
      disabled: isCepApproved || !filteredTargetsFacetsOption.biosSegments?.length,
    },
    imsSegments: {
      label: t('maya_dynamic_target_list_form_imsSegments_label'),
      name: 'imsSegments',
      placeholder: t('maya_dynamic_target_list_form_imsSegments_placeholder'),
      options: filteredTargetsFacetsOption.imsSegments || [],
      required: false,
      defaultValue: targetFilters?.imsSegments || [],
      disabled: isCepApproved || !filteredTargetsFacetsOption.imsSegments?.length,
    },
    approvedEmailsAffinity: {
      label: t('maya_dynamic_target_list_form_approvedEmailsAffinity_label'),
      name: 'approvedEmailsAffinity',
      placeholder: t('maya_dynamic_target_list_form_approvedEmaislAffinity_placeholder'),
      required: false,
      options: channelAffinityFilterValueOptions,
      defaultValue: targetFilters?.channelAffinity?.approvedEmails ? targetFilters?.channelAffinity.approvedEmails : undefined,
      disabled: isCepApproved,
    },
    broadcastEmailsAffinity: {
      label: t('maya_dynamic_target_list_form_broadcastEmailsAffinity_label'),
      name: 'broadcastEmailsAffinity',
      placeholder: t('maya_dynamic_target_list_form_broadcastEmailsAffinity_placeholder'),
      required: false,
      options: channelAffinityFilterValueOptions,
      defaultValue: targetFilters?.channelAffinity?.broadcastEmails ? targetFilters?.channelAffinity.broadcastEmails : undefined,
      disabled: isCepApproved,
    },
    f2fAffinity: {
      label: t('maya_dynamic_target_list_form_f2fAffinity_label'),
      name: 'f2fAffinity',
      placeholder: t('maya_dynamic_target_list_form_f2fAffinity_placeholder'),
      required: false,
      options: channelAffinityFilterValueOptions,
      defaultValue: targetFilters?.channelAffinity?.f2f ? targetFilters?.channelAffinity.f2f : undefined,
      disabled: isCepApproved,
    },
    f2fEventAffinity: {
      label: t('maya_dynamic_target_list_form_f2fEventAffinity_label'),
      name: 'f2fEventAffinity',
      placeholder: t('maya_dynamic_target_list_form_f2fEventAffinity_placeholder'),
      required: false,
      options: channelAffinityFilterValueOptions,
      defaultValue: targetFilters?.channelAffinity?.f2fEvent ? targetFilters?.channelAffinity.f2fEvent : undefined,
      disabled: isCepApproved,
    },
    phoneAffinity: {
      label: t('maya_dynamic_target_list_form_phoneAffinity_label'),
      name: 'phoneAffinity',
      placeholder: t('maya_dynamic_target_list_form_phoneAffinity_placeholder'),
      required: false,
      options: channelAffinityFilterValueOptions,
      defaultValue: targetFilters?.channelAffinity?.phone ? targetFilters?.channelAffinity.phone : undefined,
      disabled: isCepApproved,
    },
    virtualAffinity: {
      label: t('maya_dynamic_target_list_form_virtualAffinity_label'),
      name: 'virtualAffinity',
      placeholder: t('maya_dynamic_target_list_form_virtualAffinity_placeholder'),
      required: false,
      options: channelAffinityFilterValueOptions,
      defaultValue: targetFilters?.channelAffinity?.virtual ? targetFilters?.channelAffinity.virtual : undefined,
      disabled: isCepApproved,
    },
    virtualEventAffinity: {
      label: t('maya_dynamic_target_list_form_virtualEventAffinity_label'),
      name: 'virtualEventAffinity',
      placeholder: t('maya_dynamic_target_list_form_virtualEventAffinity_placeholder'),
      required: false,
      options: channelAffinityFilterValueOptions,
      defaultValue: targetFilters?.channelAffinity?.virtualEvent ? targetFilters?.channelAffinity.virtualEvent : undefined,
      disabled: isCepApproved,
    },
    webSessionsAffinity: {
      label: t('maya_dynamic_target_list_form_webSessionsAffinity_label'),
      name: 'webSessionsAffinity',
      placeholder: t('maya_dynamic_target_list_form_webSessionsAffinity_placeholder'),
      required: false,
      options: channelAffinityFilterValueOptions,
      defaultValue: targetFilters?.channelAffinity?.webSessions ? targetFilters?.channelAffinity.webSessions : undefined,
      disabled: isCepApproved,
    },
    allF2FAffinity: {
      label: t('maya_dynamic_target_list_form_allF2FAffinity_label'),
      name: 'allF2FAffinity',
      placeholder: t('maya_dynamic_target_list_form_allF2FAffinity_placeholder'),
      required: false,
      options: channelAffinityFilterValueOptions,
      defaultValue: targetFilters?.channelAffinity?.allF2F ? targetFilters?.channelAffinity.allF2F : undefined,
      disabled: isCepApproved,
    },
    allDigitalAffinity: {
      label: t('maya_dynamic_target_list_form_allDigitalAffinity_label'),
      name: 'allDigitalAffinity',
      placeholder: t('maya_dynamic_target_list_form_allDigitalAffinity_placeholder'),
      required: false,
      options: channelAffinityFilterValueOptions,
      defaultValue: targetFilters?.channelAffinity?.allDigital ? targetFilters?.channelAffinity.allDigital : undefined,
      disabled: isCepApproved,
    },
    hcoNames: {
      label: t('maya_dynamic_target_list_form_hcoNames_label'),
      name: 'hcoNames',
      placeholder: t('maya_dynamic_target_list_form_hcoNames_placeholder'),
      required: false,
      options: targetFiltersOptions.hcoNames || [],
      defaultValue: targetFilters?.hcoNames?.length ? targetFilters?.hcoNames : [],
      disabled: isCepApproved,
    },
    consent: {
      label: t('maya_dynamic_target_list_form_consent_label'),
      name: 'consent',
      placeholder: t('maya_dynamic_target_list_form_consent_placeholder'),
      required: false,
      options: targetFiltersOptions.consent || [],
      defaultValue: targetFilters?.consent?.length ? targetFilters?.consent : [],
      disabled: isCepApproved,
    },
    cyclePlan: {
      label: t('maya_dynamic_target_list_form_cyclePlan_label'),
      name: 'cyclePlan',
      placeholder: t('maya_dynamic_target_list_form_cyclePlan_placeholder'),
      required: false,
      options: booleanOptions,
      defaultValue: BooleanOptionsValue[targetFilters?.cyclePlan?.toString() as keyof typeof BooleanOptionsValue] || null,
      disabled: isCepApproved,
    },
  };

  return {
    dynamicTargetListConfig,
  };
};

export default useDynamicTargetListConfig;
