import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import {
  gridListStyle,
  gridListHeadStyle,
  gridListFiltersStyle,
  gridListCardStyle,
  gridListDataStyle,
  onCellClickStyle,
} from './GridList.style';
import { GridListPropsType } from './GridList.types';
import useGridList from './GrildList.hook';
import ContentCard from 'components/ContentCard';
import GridListPagination from 'modules/maya/components/GridListPagination/GridListPagination';
import Heading from 'modules/maya/components/Heading';
import Box from 'yoda-ui/components/Box';
import Button from 'yoda-ui/components/Button';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import DataGrid from 'yoda-ui/components/DataGrid';

const GridList = <T extends Record<string, unknown>>(props: GridListPropsType<T>) => {
  const {
    title,
    columnConfig,
    rows,
    newElementButton,
    customFilters,
    loading,
    getRowId,
    getRowClassName,
    rowCountState,
    onPageChange,
    onPageSizeChange,
    disableColumnMenu,
    hideFooterPagination = false,
    onCellClick,
    sx,
  } = props;

  const {
    pageSize,
    setPageSize,
    getRowSpacing,
  } = useGridList();

  const computedGridListDataStyle = onCellClick ? { ...gridListDataStyle, ...onCellClickStyle } : gridListDataStyle;

  return (
    <Box sx={ gridListStyle }>
      <Box sx={ gridListHeadStyle }>
        { title && (<Heading>{ title }</Heading>) }
        {
          (() => {
            if (newElementButton?.newElementClick && newElementButton?.newElementLabel) {
              return <Button
                buttonType={ ButtonType.primary }
                onClick={ newElementButton.newElementClick }
                disabled={ newElementButton?.newElementDisabled || false }
              >
                { newElementButton.newElementLabel }
              </Button>;
            }
            return null;
          })()
        }
      </Box>
      <Box sx={ gridListFiltersStyle }>
        { customFilters }
      </Box>
      <ContentCard sx={ gridListCardStyle }>
        <DataGrid
          sx={ { ...computedGridListDataStyle, ...sx } as SxProps<Theme> }
          getRowId={ getRowId }
          loading={ loading }
          rowCount={ rowCountState }
          rowsPerPageOptions={ [10, 20, 50] }
          pagination
          pageSize={ pageSize }
          paginationMode="server"
          onPageChange={ onPageChange }
          onPageSizeChange={ onPageSizeChange }
          disableColumnMenu={ disableColumnMenu }
          getRowSpacing={ getRowSpacing }
          getRowClassName={ getRowClassName }
          components={
            {
              Pagination: () => {
                return <GridListPagination pageSize={ pageSize } setPageSize={ setPageSize } />;
              },
            }
          }
          rows={ rows }
          columns={ columnConfig }
          disableColumnSelector
          hideFooterPagination={ hideFooterPagination }
          onCellClick={ onCellClick }
        />
      </ContentCard>
    </Box>
  );
};

export default GridList;
