import { useEffect } from 'react';
import { DashboardFilter } from 'generated/maya.types';
import useGetTargetsReachQuery from 'modules/maya/graphql/queries/getTargetsReach';

const useTargetsReachWidget = (filters?: DashboardFilter) => {
  const { getTargetsReachQuery, targetsReach, loading, error } = useGetTargetsReachQuery();

  useEffect(() => {
    if (filters) {
      getTargetsReachQuery({ variables: { filters } });
    }
  }, [filters, getTargetsReachQuery]);

  return {
    value: `${targetsReach}%`,
    loading,
    error,
  };
};

export default useTargetsReachWidget;
