import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Heading from 'modules/maya/components/Heading';
import MayaLayout from 'modules/maya/components/MayaLayout';
import StrategicImperativeForm from 'modules/maya/components/StrategicImperativeForm/StrategicImperativeForm';
import useStrategicImperativeEdit from 'modules/maya/routes/StrategicImperativeEdit/StrategicImperativeEdit.hook';
import Box from 'yoda-ui/components/Box';
import Loader from 'yoda-ui/components/Loader';

const StrategicImperativeEdit: FC = () => {
  const { t } = useTranslation();
  const {
    loading,
    strategicImperative,
    handleSaveStrategicImperativeForm,
    handleCancelStrategicImperativeForm,
  } = useStrategicImperativeEdit();

  return (
    <MayaLayout>
      <Heading>
        { t('maya_strategic_imperative_form_edit_title') }
      </Heading>
      {
        loading
          ? (
            <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
              <Loader center />
            </Box>
          )
          : (
            <StrategicImperativeForm
              {
                ...{
                  strategicImperative,
                  submitCallback: handleSaveStrategicImperativeForm,
                  cancelCallback: handleCancelStrategicImperativeForm,
                }
              }
            />
          )
      }
    </MayaLayout>
  );
};

export default StrategicImperativeEdit;
