import { useEffect, useMemo, useState } from 'react';
import { useSnapshot } from 'valtio';
import { Target } from 'generated/maya.types';
import { PAGINATION_PAGE_SIZE } from 'modules/maya/basics/constants/common.constant';
import { CepStateType } from 'modules/maya/basics/types/maya.types';
import useGetTargetListByCepIdQuery from 'modules/maya/graphql/queries/getTargetListByCepId';
import useTargetListViewConfig from 'modules/maya/routes/CepView/Steps/TargetListView/TargetListView.config';
import cepState from 'modules/maya/states/cep.states';

const useTargetListView = () => {
  const { targetListConfig } = useTargetListViewConfig();

  const cepStateValue = useSnapshot(cepState) as CepStateType;

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(0);
  const [targets, setTargets] = useState<Target[]>([]);

  const queryOptions = useMemo(
    () => ({
      limit: pageSize,
      offset: page * pageSize,
    }),
    [page, pageSize],
  );

  const cepId = cepStateValue.cep?._id ? cepStateValue.cep._id : null;
  const { data, loading, count } = useGetTargetListByCepIdQuery(cepId, queryOptions);

  useEffect(() => {
    setRowCountState((prevRowCountState) => (count !== undefined ? count : prevRowCountState));
  }, [count, setRowCountState]);

  useEffect(() => {
    if (!loading) {
      setTargets(data);
    }
  }, [data, loading]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  return {
    data: targets,
    loading,
    onPageChange,
    onPageSizeChange,
    rowCountState,
    targetListConfig,
  };
};

export default useTargetListView;
