import { ApolloError, gql, useQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { QueryOptionsType } from 'basics/types/common.types';
import { StrategicImperative } from 'generated/maya.types';
import { strategicImperativeFragment } from 'modules/maya/graphql/fragments/strategicImperative.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_ALL_STRATEGIC_IMPERATIVES = gql`
query GetStrategicImperativesQuery($limit: Int!, $offset: Int!, $filters: StrategicImperativeFilter) {
  getStrategicImperatives(limit: $limit, offset: $offset, filters: $filters) {
    strategicImperatives {
      ${strategicImperativeFragment}
    }
    count
  }
}
`;

type ResultType = {
  getStrategicImperatives: {
    strategicImperatives: StrategicImperative[];
    count: number;
  };
};

type UseGetStrategicImperatives = (filters?: unknown, queryOptions?: QueryOptionsType) => {
  loading: boolean;
  error?: ApolloError;
  data: StrategicImperative[];
  count: number;
};

const useGetAllStrategicImperativeQuery: UseGetStrategicImperatives = (filters?: unknown, queryOptions = { limit: 5000, offset: 0 }) => {
  const { limit, offset } = queryOptions;
  const { data, loading, error } = useQuery<ResultType>(GET_ALL_STRATEGIC_IMPERATIVES, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    variables: {
      limit,
      offset,
      filters,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getAllStrategicImperatives.ts - useGetAllStrategicImperativeQuery',
    });
  }

  return {
    data: data?.getStrategicImperatives.strategicImperatives || [],
    count: data?.getStrategicImperatives.count || 0,
    loading,
    error,
  };
};

export default useGetAllStrategicImperativeQuery;
