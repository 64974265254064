import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { mayaStrategicImperativeListRoute } from 'basics/constants/routes.constants';
import { succesToast, warningToast } from 'basics/utils/toast';
import {
  SiFormCanceltCallbackType,
  SiFormSubmitCallbackType,
} from 'modules/maya/components/StrategicImperativeForm/StrategicImperativeForm.types';
import { useUpdateStrategicImperativeMutation } from 'modules/maya/graphql/mutations/updateStrategicImperatives';
import useGetStrategicImperativeByIdQuery from 'modules/maya/graphql/queries/getStrategicImperativeById';

const useStrategicImperativeEdit = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [updateStrategicImperative] = useUpdateStrategicImperativeMutation();

  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const [loading, setLoading] = useState(true);

  const strategicImperative = useGetStrategicImperativeByIdQuery(urlParams.get('id')).data;

  useEffect(() => {
    if (strategicImperative) {
      setLoading(false);
    }
  }, [strategicImperative, setLoading]);

  const handleCancelStrategicImperativeForm: SiFormCanceltCallbackType = () => {
    navigate(`${mayaStrategicImperativeListRoute}?year=${strategicImperative?.year}`);
  };

  const handleSaveStrategicImperativeForm: SiFormSubmitCallbackType = async (si) => {
    const { data: strategicImperativeData, error } = await updateStrategicImperative(strategicImperative?._id || null, si);

    if (strategicImperativeData && !error) {
      succesToast(t('maya_si_updated', { title: si.title }));
      navigate(`${mayaStrategicImperativeListRoute}?year=${strategicImperativeData.year}`);
    } else {
      warningToast(error || t('maya_si_updated_error', { title: si.title }));
    }
  };

  return {
    loading,
    strategicImperative,
    handleSaveStrategicImperativeForm,
    handleCancelStrategicImperativeForm,
  };
};

export default useStrategicImperativeEdit;
