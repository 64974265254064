import { useEffect, useMemo } from 'react';
import { useSnapshot } from 'valtio';
import { Tactic } from 'generated/maya.types';
import { TacticMode, TacticStatus } from 'modules/maya/basics/enums/maya.enums';
import { CepStateType } from 'modules/maya/basics/types/maya.types';
import useGetAllTacticsQuery from 'modules/maya/graphql/queries/getAllTactics';
import cepState from 'modules/maya/states/cep.states';
import tacticState from 'modules/maya/states/tactic.states';

const useTacticKanbanView = () => {
  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const cepId = cepStateValue.cep?._id;

  const queryFilters = useMemo(
    () => ({
      cep: cepId,
      status: [TacticStatus.DRAFT, TacticStatus.APPROVED],
    }),
    [cepId],
  );

  const { data, loading } = useGetAllTacticsQuery(undefined, queryFilters);

  useEffect(() => {
    tacticState.mode = TacticMode.list;
  }, []);

  useEffect(() => {
    if (!loading) {
      cepState.tactics = data;
    }
  }, [data, loading]);

  type TacticActionOrderGroup = {
    [actionOrder: string]: Tactic[];
  };

  const groupedTactics: TacticActionOrderGroup = {};
  cepStateValue.tactics.forEach((tactic) => {
    const actionOrder = tactic?.actionOrder;

    if (actionOrder) {
      if (groupedTactics[actionOrder] === undefined) {
        groupedTactics[actionOrder] = [tactic];
      } else {
        groupedTactics[actionOrder].push(tactic);
      }
    }
  });

  return {
    groupedTactics,
  };
};

export default useTacticKanbanView;
