import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { CustomerEngagementPlanning } from 'generated/maya.types';
import { cepFragment } from 'modules/maya/graphql/fragments/cep.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_CEP_BY_ID = gql`
query GetCustomerEngagementPlanningByIdQuery($id: String!) {
  getCustomerEngagementPlanningById(id: $id) {
    ${cepFragment}
  }
}
`;

type ResultType = {
  getCustomerEngagementPlanningById: CustomerEngagementPlanning;
};

type UseGetCepByIdLazy = () => {
  queryGetCepById: Function;
  loading: boolean;
  error?: ApolloError;
  data?: CustomerEngagementPlanning;
};

const useGetCepByIdLazyQuery: UseGetCepByIdLazy = () => {
  const [queryGetCepById, { data, loading, error }] = useLazyQuery<ResultType>(GET_CEP_BY_ID, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getCustomerEngagementPlanningById.ts - useGetCustomerEngagementPlanningByIdQuery',
    });
  }

  return {
    queryGetCepById,
    data: data?.getCustomerEngagementPlanningById,
    loading,
    error,
  };
};

export default useGetCepByIdLazyQuery;
