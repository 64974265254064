import { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { CepSteps, TacticDisplayType, TacticMode } from 'modules/maya/basics/enums/maya.enums';
import { CepStateType } from 'modules/maya/basics/types/maya.types';
import useSelectTacticDesignConfig from 'modules/maya/components/CepForm/Steps/TacticsStep/TacticsStep.config';
import cepState from 'modules/maya/states/cep.states';
import tacticState from 'modules/maya/states/tactic.states';
import { useYodaForm } from 'yoda-ui/yodaForm';

const useTacticsStep = () => {
  const [displayType, setdisplayType] = useState<TacticDisplayType>(TacticDisplayType.kanban);
  const { useWatchStep } = useYodaForm();
  const stepState = useWatchStep(CepSteps.tacticsStep);
  const cepStateValue = useSnapshot(cepState) as CepStateType;

  const { cardSwitchConfig } = useSelectTacticDesignConfig(displayType, setdisplayType);

  useEffect(() => {
    tacticState.cepId = cepStateValue.cep?._id || '';
    tacticState.tactic = null;
    tacticState.mode = TacticMode.list;
  }, [cepStateValue.cep?._id]);

  useEffect(() => {
    return function cleanup() {
      tacticState.cepId = '';
      tacticState.tactic = null;
      tacticState.mode = TacticMode.list;
    };
  }, []);

  return {
    stepIsValid: stepState?.isValid || false,
    cardSwitchConfig,
    displayType,
  };
};

export default useTacticsStep;
