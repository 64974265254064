import { SelectOption } from 'basics/options/options.types';
import { Channel } from 'modules/maya/basics/enums/maya.enums';

const channelsOptions: SelectOption[] = [

  { id: Channel.approvedEmail, label: 'Approved eMail' },
  { id: Channel.broadcastEmail, label: 'Broadcast eMail' },
  { id: Channel.f2fEvent, label: 'F2F Event' },
  { id: Channel.f2fMeeting, label: 'F2F Meeting' },
  { id: Channel.hybridEvent, label: 'Hybrid Event' },
  { id: Channel.oneToOne, label: '1:1' },
  { id: Channel.telephone, label: 'Telephone' },
  { id: Channel.virtualEvent, label: 'Virtual Event' },
  { id: Channel.virtualMeeting, label: 'Virtual Meeting' },
  { id: Channel.webLogIn, label: 'Web login' },
  { id: Channel.webNoLogIn, label: 'Web no login' },
];

export default channelsOptions;

