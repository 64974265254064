import { useTranslation } from 'react-i18next';
import useCepTemplateSelectorFiltersConfig from 'modules/maya/components/CepTemplateSelectorFilters/CepTemplateSelectorFilters.config';
import { CepTemplateSelectorFiltersType } from 'modules/maya/components/CepTemplateSelectorForm/CepTemplateSelectorForm.types';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import { useYodaCreateForm } from 'yoda-ui/yodaForm';

type UseCepTemplateSelectorFiltersPropsType = {
  setCepTemplateSelectorFilters: (value: CepTemplateSelectorFiltersType) => void;
};

const useCepTemplateSelectorFilters = ({ setCepTemplateSelectorFilters }: UseCepTemplateSelectorFiltersPropsType) => {
  const { providerFields, getValues } = useYodaCreateForm();
  const { cepTemplateSelectorFiltersConfig } = useCepTemplateSelectorFiltersConfig();
  const { t } = useTranslation();

  const populateFilters = () => {
    const filtersValues = getValues();
    setCepTemplateSelectorFilters({
      ...(filtersValues.customerType && filtersValues.customerType.length > 0 ? { customerType: filtersValues.customerType } : {}),
      ...(filtersValues.specialty && filtersValues.specialty.length > 0 ? { specialty: filtersValues.specialty } : {}),
      ...(filtersValues.channel && filtersValues.channel.length > 0 ? { channel: filtersValues.channel } : {}),
    });
  };

  const submitButtonConfig = {
    buttonProps: {
      onClick: populateFilters,
      buttonType: ButtonType.primary,
      disabled: false,
    },
    label: t('actions_search_label'),
  };

  return {
    cepTemplateSelectorFiltersConfig,
    providerFields,
    submitButtonConfig,
  };
};

export default useCepTemplateSelectorFilters;
