import { CepSteps, CepGroups } from 'modules/maya/basics/enums/cep.enums';

export const configurationStepFields = {
  biogenFunction: `${CepSteps.configurationStep}.${CepGroups.configuration}.biogenFunction`,
  ciDateEnd: `${CepSteps.configurationStep}.${CepGroups.configuration}.ciDateEnd`,
  ciDateStart: `${CepSteps.configurationStep}.${CepGroups.configuration}.ciDateStart`,
  customerInitiative: `${CepSteps.configurationStep}.${CepGroups.configuration}.customerInitiative`,
  customerType: `${CepSteps.configurationStep}.${CepGroups.configuration}.customerType`,
  date: `${CepSteps.configurationStep}.${CepGroups.configuration}.date`,
  description: `${CepSteps.configurationStep}.${CepGroups.configuration}.description`,
  keyMessages: `${CepSteps.configurationStep}.${CepGroups.configuration}.keyMessages`,
  kpi: `${CepSteps.configurationStep}.${CepGroups.configuration}.kpi`,
  product: `${CepSteps.configurationStep}.${CepGroups.configuration}.product`,
  specialty: `${CepSteps.configurationStep}.${CepGroups.configuration}.specialty`,
  therapeuticArea: `${CepSteps.configurationStep}.${CepGroups.configuration}.therapeuticArea`,
  title: `${CepSteps.configurationStep}.${CepGroups.configuration}.title`,
};

