export enum EventBiogenBrand {
  AD_DEMENTIA = 'AD / Dementia',
  ADUCANUMAB = 'Aducanumab',
  ADUHELM = 'Aduhelm',
  AVONEX = 'Avonex',
  BENEPALI = 'Benepali',
  BIOGEN = 'Biogen',
  Biosimilar = 'Biosimilar',
  BIOSIMILARS_FRANCHISE = 'Biosimilars Franchise',
  BLASTOFERON = 'Blastoferon',
  CIMZIA = 'Cimzia',
  CLADRIBINE = 'Cladribine',
  CLINICAL_COMPOUND = 'Clinical Compound',
  COSENTYX = 'Cosentyx',
  DISEASE_STATE_EDUCATION = 'Disease State Education',
  ENBREL = 'Enbrel',
  FAMPYRA = 'Fampyra',
  FIBRONEURINA = 'Fibroneurina',
  FLIXABI = 'Flixabi',
  FUMADERM = 'Fumaderm',
  HUMIRA = 'Humira',
  IMRALDI = 'Imraldi',
  INFLECTRA = 'Inflectra',
  INMUNOMAS = 'Inmunomas',
  INTERFERON = 'Interferon',
  MS = 'MS',
  MS_FRANCHISE = 'MS Franchise',
  MULTIBRANDED = 'Multibranded',
  OMAVELOXOLONE = 'Omaveloxolone',
  OTEZLA = 'Otezla',
  PLEGRIDY = 'Plegridy',
  PLEGRIDY_IM = 'Plegridy IM',
  PLEGRIDY_SC = 'Plegridy SC',
  POLIMUNOL = 'Polimunol',
  PONVORY = 'Ponvory',
  RARE_FRANCHISE = 'Rare Franchise',
  REMICADE = 'Remicade',
  REMSIMA = 'Remsima',
  RITUXAN = 'Rituxan',
  SIMPONI = 'Simponi',
  SIPONIMOD = 'Siponimod',
  SMA = 'SMA',
  SKYCLARYS = 'Skyclarys',
  SPINRAZA = 'Spinraza',
  TECFIDERA = 'Tecfidera',
  TYSABRI = 'Tysabri',
  TYSABRI_IV = 'Tysabri IV',
  TYSABRI_SC = 'Tysabri SC',
  UNBRANDED = 'Unbranded',
  VERUM = 'Verum',
  VUMERITY = 'Vumerity',
  ZINBRYTA = 'Zinbryta',
  ZOLGENSMA = 'Zolgensma',
}
