import { gql, MutationResult, useMutation } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { Tactic, TacticInput } from 'generated/maya.types';
import { MutationReturnType } from 'modules/maya/basics/types/maya.types';
import { tacticFragment } from 'modules/maya/graphql/fragments/tactic.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const updateTacticMutationCall = gql`
mutation updateTactic($id: String!, $updateTactic: UpdateTacticInput!, $cepId: String!, $sendToVeeva: Boolean!) {
  updateTactic(id: $id, updateTacticInput: $updateTactic, cepId: $cepId, sendToVeeva: $sendToVeeva) {
    ${tacticFragment}
  }
}
`;

type ResultType = {
  updateTactic: Tactic;
};

type UseUpdateTacticMutation = [
  (
    id: string | null,
    tactic: Partial<TacticInput>,
    cepId: string,
    sendToVeeva?: boolean
  ) => Promise<MutationReturnType<Tactic>>,
  MutationResult<ResultType>,
];

export const useUpdateTacticMutation = (): UseUpdateTacticMutation => {
  const [updateTactic, state] = useMutation<ResultType>(updateTacticMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  const flags = useFlags();

  const executeMutation = useCallback(async (id, tactic, cepId, sendToVeeva = true) => {
    try {
      const { data } = await updateTactic({
        variables: {
          id,
          updateTactic: tactic,
          cepId,
          sendToVeeva: flags.mayaEnableVeevaBiogenlinc1816 && sendToVeeva,
        },
      });

      return { data: data?.updateTactic || null, error: null };
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, tactic);
        Logger.logError({
          error,
          customContext,
          transactionName: 'updateTactic.ts - useUpdateTacticMutation',
        });

        return { data: null, error: customContext.message as string };
      }

      return { data: null, error: null };
    }
  }, [flags.mayaEnableVeevaBiogenlinc1816, updateTactic]);

  return [executeMutation, state];
};
