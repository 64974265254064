import { gql, MutationResult, useMutation } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { CustomerInitiative } from 'generated/maya.types';
import { MutationReturnType } from 'modules/maya/basics/types/maya.types';
import { customerInitiativeFragment } from 'modules/maya/graphql/fragments/customerInitiative.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const createCustomerInitiativeMutationCall = gql`
mutation createCustomerInitiative($createCustomerInitiative: CustomerInitiativeInput!, $sendToVeeva: Boolean!) {
  createCustomerInitiative(createCustomerInitiativeInput: $createCustomerInitiative, sendToVeeva: $sendToVeeva) {
    ${customerInitiativeFragment}
  }
}
`;

type ResultType = {
  createCustomerInitiative: CustomerInitiative;
};

type UseCreateCustomerInitiativeMutation = [
  (customerInitiative: Partial<CustomerInitiative>, sendToVeeva?: boolean) => Promise<MutationReturnType<CustomerInitiative>>,
  MutationResult<ResultType>,
];

export const useCreateCustomerInitiativeMutation = (): UseCreateCustomerInitiativeMutation => {
  const [createCustomerInitiative, state] = useMutation<ResultType>(createCustomerInitiativeMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });
  const flags = useFlags();
  const executeMutation = useCallback(async (customerInitiative, sendToVeeva = true) => {
    try {
      const { data } = await createCustomerInitiative({
        variables: {
          createCustomerInitiative: customerInitiative,
          sendToVeeva: flags.mayaEnableVeevaBiogenlinc1816 && sendToVeeva,
        },
      });

      return { data: data?.createCustomerInitiative || null, error: null };
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, customerInitiative);
        Logger.logError({
          error,
          customContext,
          transactionName: 'createCustomerInitiative.ts - useCreateCustomerInitiativeMutation',
        });

        return { data: null, error: customContext.message as string };
      }

      return { data: null, error: null };
    }
  }, [createCustomerInitiative, flags.mayaEnableVeevaBiogenlinc1816]);

  return [executeMutation, state];
};
