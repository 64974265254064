import { CustomerEngagementPlanning } from 'generated/maya.types';
import { MutationReturnType } from 'modules/maya/basics/types/maya.types';

export type CepFormSubmitCallbackType = (
  cep: Partial<CustomerEngagementPlanning>
) => Promise<MutationReturnType<CustomerEngagementPlanning>>;

export type CepFormPropsType = {
  submitCallback: CepFormSubmitCallbackType;
  isTemplate: boolean;
};

export enum CepFormStepsIndex {
  configurationStep = 0,
  targetListStep = 1,
  tacticsStep = 2,
  goalStep = 3,
}
