import { FC } from 'react';
import ContentCard from 'components/ContentCard';
import { FormLayoutContainer } from 'components/FormLayout';
import { loadingBoxStyle } from 'modules/maya/components/DynamicTargetListForm/DynamicTargetListForm.style';
import useCepFormGoalGroup from 'modules/maya/groups/CepFormGoalGroup/CepFormGoalGroup.hook';
import Box from 'yoda-ui/components/Box';
import SliderYoda from 'yoda-ui/components/Form/SliderYoda';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import Loader from 'yoda-ui/components/Loader';
import { YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

const CepFormGoalGroup: FC = () => {
  const { cepFormGoalGroup, engagementLoading, reachLoading } = useCepFormGoalGroup();

  return (
    <>
      <ContentCard>
        <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } flexDirection="column" >
          <Box>
            {
              reachLoading
                ? (
                  <Box sx={ loadingBoxStyle }>
                    <Loader center size={ 1 } />
                  </Box>
                )
                : <SliderYoda { ...cepFormGoalGroup.reachGoal } />
            }

          </Box>
          <Box>
            {
              engagementLoading
                ? (
                  <Box sx={ loadingBoxStyle }>
                    <Loader center size={ 1 } />
                  </Box>
                )
                : null
            }
            <TextYoda
              { ...cepFormGoalGroup.targetEngagementGoal }
              disabled={ engagementLoading || cepFormGoalGroup.targetEngagementGoal.disabled }
            />
          </Box>
          <Box paddingTop={ YodaSpacing.large }>
            <TextYoda { ...cepFormGoalGroup.npsGoal } />
          </Box>
        </FormLayoutContainer>
      </ContentCard>
    </>
  );
};

export default CepFormGoalGroup;
