import { GridCellParams } from '@mui/x-data-grid';
import { t } from 'i18next';
import { cloneDeep, debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  mayaCepCreateRoute,
  mayaCiViewRoute,
  mayaCustomerInitiativeCreateRoute,
  mayaCustomerInitiativeDuplicateRoute,
  mayaCustomerInitiativeEditRoute,
} from 'basics/constants/routes.constants';
import { errorToast, succesToast, warningToast } from 'basics/utils/toast';
import { useCurrentUserState } from 'contexts/user';
import { CustomerInitiative } from 'generated/maya.types';
import { PAGINATION_PAGE_SIZE } from 'modules/maya/basics/constants/common.constant';
import { CiStatus, VeevaSyncStatus } from 'modules/maya/basics/enums/maya.enums';
import { useSetCiStatusToActiveMutation } from 'modules/maya/graphql/mutations/setCiStatusToActive';
import { useSetCiStatusToDeletedMutation } from 'modules/maya/graphql/mutations/setCiStatusToDeleted';
import { useSyncCiToVeevaMutation } from 'modules/maya/graphql/mutations/syncCiToVeeva';
import useGetAllCustomerInitiativeQuery from 'modules/maya/graphql/queries/getAllCustomerInitiatives';
import useCustomerInitiativeListConfig from 'modules/maya/routes/CustomerInitiativeList/CustomerInitiativeList.config';

const useCustomerInitiativeList = () => {
  const navigate = useNavigate();
  const [ciTitleFilter, setCiTitleFilter] = useState<(string)>();
  const [cis, setCis] = useState<CustomerInitiative[]>([]);
  const [setCiStatusToDeleted, { loading: removeCiLoading }] = useSetCiStatusToDeletedMutation();
  const [syncCiToVeeva] = useSyncCiToVeevaMutation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(0);
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState('');
  const { user } = useCurrentUserState();
  const urlParams = new URLSearchParams(window.location.search);
  const year = urlParams.get('year');
  const [setCiStatusToActive] = useSetCiStatusToActiveMutation();

  const queryOptions = useMemo(
    () => ({
      limit: pageSize,
      offset: page * pageSize,
    }),
    [page, pageSize],
  );

  const queryFilters = useMemo(
    () => ({
      title: ciTitleFilter,
      isTemplate: false,
      status: [CiStatus.ACTIVE, CiStatus.DRAFT],
      countries: user?.country ? [user?.country] : undefined,
      year: year ? parseInt(year, 10) : undefined,
    }),
    [ciTitleFilter, user?.country, year],
  );

  const { data, loading, count } = useGetAllCustomerInitiativeQuery(queryOptions, queryFilters);

  useEffect(() => {
    setRowCountState((prevRowCountState) => (count !== undefined ? count : prevRowCountState));
  }, [count, setRowCountState]);

  useEffect(() => {
    if (!loading) {
      setCis(data);
    }
  }, [data, loading]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const handleChangeCiTitle = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCiTitleFilter(event.target.value);
  };
  const debouncedHandleChangeCiTitle = useMemo(
    () => debounce(handleChangeCiTitle, 750),
    [],
  );

  const editCiCallback = async (ciId: string) => {
    navigate(`${mayaCustomerInitiativeEditRoute}?id=${ciId}`);
  };

  const duplicateCiCallback = async (ciId: string) => {
    navigate(`${mayaCustomerInitiativeDuplicateRoute}?sourceId=${ciId}`);
  };

  const addChildAction = async (ciId: string) => {
    navigate(`${mayaCepCreateRoute}?parentId=${ciId}`);
  };

  const deleteCiCallback = async (ciId: string) => {
    const { data: ciDeleted, error: cidDeletedError } = await setCiStatusToDeleted(ciId);
    if (ciDeleted && !cidDeletedError) {
      succesToast(t('maya_action_deleting_ci_success'));
      const clonedCis = cloneDeep(cis);
      const targetCiIndex = clonedCis.findIndex((ci) => { return ci._id === ciId; });
      if (targetCiIndex !== -1) {
        clonedCis.splice(targetCiIndex, 1);
        setCis(clonedCis);
      }
    } else {
      warningToast(cidDeletedError || t('errors_standard'));
    }

    setOpen(false);
  };

  const approveCiCallback = async (ciId: string) => {
    const isCiApproved = await setCiStatusToActive(ciId);

    if (isCiApproved.data) {
      succesToast(t('maya_action_activation_ci_success'));
      const clonedCis = cloneDeep(cis);
      const targetCiIndex = clonedCis.findIndex((ci) => { return ci._id === ciId; });

      if (targetCiIndex) {
        const updatedTargetCi = cloneDeep(clonedCis[targetCiIndex]);
        updatedTargetCi.status = CiStatus.ACTIVE;
        clonedCis.splice(targetCiIndex, 1, updatedTargetCi);
        setCis(clonedCis);
      }
    } else if (isCiApproved.error) {
      errorToast(isCiApproved.error);
    }
  };

  const syncCiCallback = async (ciId: string) => {
    const clonedCis = cloneDeep(cis);
    const targetCiIndex = clonedCis.findIndex((ci) => { return ci._id === ciId; });
    const updatedTargetCi = cloneDeep(clonedCis[targetCiIndex]);

    const { data: ciSynched, error: ciSynchedError } = await syncCiToVeeva(ciId);
    if (ciSynched && !ciSynchedError) {
      succesToast(t('maya_action_sync_ci_success'));
      updatedTargetCi.veevaSyncStatus = VeevaSyncStatus.SYNC;
    } else {
      warningToast(ciSynchedError || t('maya_action_sync_ci_error'));
      updatedTargetCi.veevaSyncStatus = VeevaSyncStatus.ERROR;
    }

    clonedCis.splice(targetCiIndex, 1, updatedTargetCi);
    setCis(clonedCis);
  };

  const openModalCallback = (ciId: string) => {
    setOpen(true);
    setRowId(ciId);
  };

  const { customerInitiativeListColumnConfig, ciTitleFieldConfig } = useCustomerInitiativeListConfig(
    editCiCallback,
    duplicateCiCallback,
    addChildAction,
    openModalCallback,
    syncCiCallback,
    approveCiCallback,
  );

  const newCustomerInitiativeClick = () => {
    navigate(mayaCustomerInitiativeCreateRoute);
  };

  const handleClick = (params: GridCellParams) => {
    if (params.field !== 'action') {
      navigate(`${mayaCiViewRoute}?id=${params.row._id}`);
    }
  };

  const menuCustomerInitiativeLabel = t('maya_main_menu_customer_initiative_label');

  const newElementButton = {
    newElementLabel: t('maya_list_new_element_label', { elementTitle: menuCustomerInitiativeLabel }),
    newElementClick: newCustomerInitiativeClick,
  };

  return {
    cis,
    ciTitleFieldConfig,
    customerInitiativeListColumnConfig,
    debouncedHandleChangeCiTitle,
    deleteCiCallback,
    handleClick,
    loading,
    menuCustomerInitiativeLabel,
    newElementButton,
    onPageChange,
    onPageSizeChange,
    open,
    removeCiLoading,
    rowCountState,
    rowId,
    setOpen,
  };
};

export default useCustomerInitiativeList;
