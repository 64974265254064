import { gql, MutationResult, useMutation } from '@apollo/client';
import useFlags from 'launchdarkly-react-client-sdk/lib/useFlags';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { Tactic, TacticInput } from 'generated/maya.types';
import { MutationReturnType } from 'modules/maya/basics/types/maya.types';
import { tacticFragment } from 'modules/maya/graphql/fragments/tactic.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const createTacticMutationCall = gql`
mutation createTactic($createTactic: TacticInput!, $cepId: String!, $sendToVeeva: Boolean!) {
  createTactic(createTacticInput: $createTactic, cepId: $cepId, sendToVeeva: $sendToVeeva) {
    ${tacticFragment} 
  }
}
`;

type ApiResultType = {
  createTactic: Tactic;
};

type UseCreateTacticMutation = [
  (tactic: Partial<TacticInput>, cepId: string, sendToVeeva?: boolean) => Promise<MutationReturnType<Tactic>>,
  MutationResult<ApiResultType>,
];

export const useCreateTacticMutation = (): UseCreateTacticMutation => {
  const [createTactic, state] = useMutation<ApiResultType>(createTacticMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });
  const flags = useFlags();

  const executeMutation = useCallback(async (tactic, cepId: string, sendToVeeva = true) => {
    try {
      const { data } = await createTactic({
        variables: {
          cepId,
          createTactic: tactic,
          sendToVeeva: flags.mayaEnableVeevaBiogenlinc1816 && sendToVeeva,
        },
      });

      return { data: data?.createTactic || null, error: null };
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, tactic);
        Logger.logError({
          error,
          customContext,
          transactionName: 'createTactic.ts - useCreateTacticMutation',
        });

        return { data: null, error: customContext.message as string };
      }

      return { data: null, error: null };
    }
  }, [createTactic, flags.mayaEnableVeevaBiogenlinc1816]);

  return [executeMutation, state];
};
