import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import GridListAction from 'modules/maya/components/GridListAction/GridListAction';

type UseCepTemplateSelectorListConfigPropsType = {
  cepFromTemplateCallback: (id: string) => void;
};

const useCepTemplateSelectorListConfig = ({
  cepFromTemplateCallback,
}: UseCepTemplateSelectorListConfigPropsType) => {
  const { t } = useTranslation();

  const cepTemplateListColumnConfig: GridColDef[] = [
    {
      field: 'title',
      headerName: t('maya_cep_template_selector_list_title'),
      flex: 1,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'popularity',
      headerName: t('maya_cep_template_selector_list_popularity'),
      flex: 0.1,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'action',
      headerName: '',
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params: GridRenderCellParams) => {
        const actions = [
          {
            label: t('actions_create_cep_from_tpl'),
            callback: () => { cepFromTemplateCallback(params.row._id); },
          },
        ];

        return (
          <GridListAction
            actions={ actions }
          />
        );
      },
      align: 'right',
      headerAlign: 'right',
      flex: 0.1,
    },
  ];

  return {
    cepTemplateListColumnConfig,
  };
};

export default useCepTemplateSelectorListConfig;
