import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportFormPropsType } from 'modules/maya/components/ExportForm/ExportForm.types';
import FormContainer from 'modules/maya/components/FormContainer/FormContainer';
import Heading from 'modules/maya/components/Heading';
import Box from 'yoda-ui/components/Box';
import MultiSelectYoda from 'yoda-ui/components/Form/SelectYoda/MultiSelectYoda';

const ExportForm: FC<ExportFormPropsType> = ({
  exportFormConfig,
  providerFields,
  handleChangeCountrySelected,
}) => {
  const { t } = useTranslation();

  return (
    <FormContainer providerFields={ providerFields } >
      <Heading>
        { t('maya_export_title') }
      </Heading>
      <Box>
        <MultiSelectYoda { ...exportFormConfig.countries } onChange={ handleChangeCountrySelected } />
      </Box>
    </FormContainer>
  );
};

export default ExportForm;
