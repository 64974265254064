import { CiFilter, CustomerInitiative } from 'generated/maya.types';
import { CiStatus } from 'modules/maya/basics/enums/maya.enums';
import useGetAllCustomerInitiativeTypeQuery from 'modules/maya/graphql/queries/getAllCustomerInitiatives';

const useCustomerInitiativeTypeOptions = (filters: CiFilter = {}) => {
  const { data: customerInitiativeType } = useGetAllCustomerInitiativeTypeQuery(
    undefined,
    {
      status: [CiStatus.DRAFT, CiStatus.ACTIVE],
      ...filters,
    },
  );
  const customerInitiativeOptions = customerInitiativeType.map((element: CustomerInitiative) => {
    return {
      id: element._id,
      label: element.title,
    };
  });

  return customerInitiativeOptions;
};

export default useCustomerInitiativeTypeOptions;

