/* eslint-disable react-hooks/rules-of-hooks */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import UserAvatar from 'components/UserAvatar';
import { useCurrentUserState } from 'contexts/user';
import useAuth from 'hooks/useAuth';
import { CountrySelector as MayaCountrySelector } from 'modules/maya/components/CountrySelector/CountrySelector';
import Button from 'yoda-ui/components/Button';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';

type NavbarContentProps = {
  displayCountrySelector: boolean;
  currentPath: string;
};

const NavbarContent: FC<NavbarContentProps> = ({ displayCountrySelector, currentPath }) => {
  const { user, userLoading } = useCurrentUserState();
  const { handleLogin, handleLogout } = useAuth();
  const { t } = useTranslation();

  if (userLoading) {
    return null;
  }

  return user
    ? (
      <>
        {
          displayCountrySelector && currentPath === '/maya'
            ? <MayaCountrySelector user={ user } /> : null
        }
        <UserAvatar user={ user } />
        <Button onClick={ handleLogout }>{ t('actions_logout_label') }</Button>
      </>
    )
    : (
      <Button onClick={ handleLogin } buttonType={ ButtonType.primary }>{ t('actions_login_label') }</Button>
    );
};

export default NavbarContent;
