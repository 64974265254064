import { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { mayaCiTemplateListRoute } from 'basics/constants/routes.constants';
import { succesToast, warningToast } from 'basics/utils/toast';
import {
  CiFormCanceltCallbackType,
  CiFormSubmitCallbackType,
} from 'modules/maya/components/CustomerInitiativeForm/CustomerInitiativeForm.types';
import { useUpdateCustomerInitiativeMutation } from 'modules/maya/graphql/mutations/updateCustomerInitiative';
import useGetCustomerInitiativeByIdQuery from 'modules/maya/graphql/queries/getCustomerInitiativeById';

const useCiTemplateEdit = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [updateCustomerInitiative] = useUpdateCustomerInitiativeMutation();

  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const location = useLocation();
  const currentURL = location.pathname;

  const [loading, setLoading] = useState(true);

  const customerInitiative = useGetCustomerInitiativeByIdQuery(urlParams.get('id')).data;

  useEffect(() => {
    if (customerInitiative) {
      setLoading(false);
    }
  }, [customerInitiative, setLoading]);

  const handleCancelCustomerInitiativeForm: CiFormCanceltCallbackType = () => {
    navigate(mayaCiTemplateListRoute);
  };

  const handleSaveCustomerInitiativeForm: CiFormSubmitCallbackType = async (ci) => {
    const { data: customerInitiativeData, error } = await updateCustomerInitiative(
      customerInitiative?._id || null,
      { ...ci, isTemplate: true },
      false,
    );

    if (customerInitiativeData && !error) {
      succesToast(t('maya_ci_updated', { title: ci.title }));
      navigate(mayaCiTemplateListRoute);
    } else {
      warningToast(error || t('maya_ci_updated_error', { title: ci.title }));
    }
  };

  return {
    loading,
    customerInitiative,
    currentURL,
    handleSaveCustomerInitiativeForm,
    handleCancelCustomerInitiativeForm,
  };
};

export default useCiTemplateEdit;
