import { FC } from 'react';
import { CountWidgetPropsType } from 'modules/maya/components/CountWidgets/CountWidget.types';
import useCepDashboardList from 'modules/maya/components/Dashboard/CepDashboardGridlist/CepDashboardList.hook';
import GridList from 'modules/maya/components/GridList/GridList';

const CepDashBoardList: FC<CountWidgetPropsType> = ({ filters }) => {
  const {
    cepListColumnConfig,
    getFilteredRows,
    cepLoading,
    onPageChange,
    onPageSizeChange,
    rowCountState,
    onCellClick,
  } = useCepDashboardList(filters);

  return (
    <GridList
      loading={ cepLoading }
      getRowId={ (row) => row._id }
      columnConfig={ cepListColumnConfig }
      rows={ getFilteredRows() }
      onPageChange={ onPageChange }
      onPageSizeChange={ onPageSizeChange }
      rowCountState={ rowCountState }
      disableColumnMenu={ true }
      onCellClick={ onCellClick }
    />
  );
};

export default CepDashBoardList;
