import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CepTemplateSelectorForm from 'modules/maya/components/CepTemplateSelectorForm/CepTemplateSelectorForm';
import Heading from 'modules/maya/components/Heading';
import MayaLayout from 'modules/maya/components/MayaLayout';

const CepTemplateSelector: FC = () => {
  const { t } = useTranslation();

  return (
    <MayaLayout>
      <Heading>
        { t('maya_choose_cep_template_title') }
      </Heading>

      <CepTemplateSelectorForm />
    </MayaLayout>
  );
};

export default CepTemplateSelector;
