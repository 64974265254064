import { FC } from 'react';
import { CurrentUser } from 'basics/types/user.types';
import { useCountrySelector } from 'modules/maya/components/CountrySelector/CountrySelector.hook';
import Box from 'yoda-ui/components/Box';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import { useYodaCreateForm, YodaFormProvider } from 'yoda-ui/yodaForm';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

type CountrySelectorProps = {
  user?: CurrentUser;
};
export const CountrySelector: FC<CountrySelectorProps> = ({ user }) => {
  const yodaFormMethods = useYodaCreateForm({ setIsValidOptional: true });
  const { countrySelectorConfig, handleCountryChange } = useCountrySelector(user);

  return (
    <YodaFormProvider { ...yodaFormMethods.providerFields }>
      <Box marginRight={ YodaSpacing.large } width={ '210px' }>
        <SelectYoda disableClearable={ true } { ...countrySelectorConfig } onChange={ handleCountryChange } />
      </Box>
    </YodaFormProvider>
  );
};
