import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { StrategicImperative } from 'generated/maya.types';
import { MutationReturnType } from 'modules/maya/basics/types/maya.types';
import { strategicImperativeFragment } from 'modules/maya/graphql/fragments/strategicImperative.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const createStrategicImperativeMutationCall = gql`
mutation createStrategicImperative($createStrategicImperative: StrategicImperativeInput!) {
  createStrategicImperative(createStrategicImperativeInput: $createStrategicImperative) {
    ${strategicImperativeFragment}
  }
}
`;

type ResultType = {
  createStrategicImperative: StrategicImperative;
};

type UseCreateStrategicImperativeMutation = [
  (strategicImperative: Partial<StrategicImperative>) => Promise<MutationReturnType<StrategicImperative>>,
  MutationResult<ResultType>,
];

export const useCreateStrategicImperativeMutation = (): UseCreateStrategicImperativeMutation => {
  const [createStrategicImperative, state] = useMutation<ResultType>(createStrategicImperativeMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  const executeMutation = useCallback(async (strategicImperative) => {
    try {
      const { data } = await createStrategicImperative({
        variables: { createStrategicImperative: strategicImperative },
      });

      return { data: data?.createStrategicImperative || null, error: null };
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, strategicImperative);
        Logger.logError({
          error,
          customContext,
          transactionName: 'createStrategicImperative.ts - useCreateStrategicImperativeMutation',
        });

        return { data: null, error: customContext.message as string };
      }

      return { data: null, error: null };
    }
  }, [createStrategicImperative]);

  return [executeMutation, state];
};
