import { FC } from 'react';
import useMayaMenu from './MayaMenu.hook';
import { mayaMenuItemsStyles, mayaMenuItemStyles } from 'modules/maya/components/MayaMenu/MayaMenu.styles';
import MayaMenuEntry from 'modules/maya/components/MayaMenuEntry';
import Box from 'yoda-ui/components/Box';

const MayaMenu: FC = () => {
  const {
    configMenu,
    currentLocation,
    navigate,
  } = useMayaMenu();

  return (
    <Box className='MayaMenu' flex={ 1 }>
      <Box className='MayaMenu-items' style={ mayaMenuItemsStyles }>
        {
          configMenu.map((itemMenu) => {
            return itemMenu.enable && (
              <Box className='MayaMenu-item' sx={ mayaMenuItemStyles } key={ itemMenu.id }>
                <MayaMenuEntry
                  title={ itemMenu.title }
                  onClick={ itemMenu.targetRoute ? () => navigate(itemMenu.targetRoute as string) : undefined }
                  currentLocation={ currentLocation }
                  activeRoute={ itemMenu.activeRoute }
                  childrens={ itemMenu.childrens }
                />
              </Box>
            );
          })
        }
      </Box>
    </Box>
  );
};

export default MayaMenu;
