import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import cepState from 'modules/maya/states/cep.states';

const useSideFormDetails = () => {
  const { t } = useTranslation();

  const cepStateValue = useSnapshot(cepState);
  let date;

  if (cepStateValue.cep?.dateStart && cepStateValue.cep?.dateEnd) {
    date = `
      ${new Date(cepStateValue.cep.dateStart).toLocaleDateString('fr-FR')} 
      - 
      ${new Date(cepStateValue.cep.dateEnd).toLocaleDateString('fr-FR')}`;
  }

  return {
    date,
    cepStateValue,
    t,
  };
};

export default useSideFormDetails;
