import { ApolloError, gql, useQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { CustomerEngagementPlanning } from 'generated/maya.types';
import { cepFragment } from 'modules/maya/graphql/fragments/cep.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_CEP_BY_ID = gql`
query GetCustomerEngagementPlanningByIdQuery($id: String!) {
  getCustomerEngagementPlanningById(id: $id) {
    ${cepFragment}
  }
}
`;

type ResultType = {
  getCustomerEngagementPlanningById: CustomerEngagementPlanning;
};

type UseGetCepById = (customerEngagementPlanningId: string | null) => {
  loading: boolean;
  error?: ApolloError;
  data?: CustomerEngagementPlanning;
};

const useGetCepByIdQuery: UseGetCepById = (customerEngagementPlanningId) => {
  const { data, loading, error } = useQuery<ResultType>(GET_CEP_BY_ID, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    skip: customerEngagementPlanningId === null,
    variables: {
      id: customerEngagementPlanningId,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getCustomerEngagementPlanningById.ts - useGetCustomerEngagementPlanningByIdQuery',
    });
  }

  return {
    data: data?.getCustomerEngagementPlanningById,
    loading,
    error,
  };
};

export default useGetCepByIdQuery;
