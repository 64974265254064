import { useTranslation } from 'react-i18next';
import useTacticsList from './TacticsList.hook';
import { kpiStyle } from 'modules/maya/components/CepForm/Steps/TacticsStep/TacticsList/TacticList.styles';
import GridList from 'modules/maya/components/GridList/GridList';
import ConfirmationDialog from 'yoda-ui/components/Dialogs/ConfirmationDialog';
import Loader from 'yoda-ui/components/Loader';
import { YodaColors } from 'yoda-ui/yodaTheme';

const TacticsList = () => {
  const {
    deleteTacticCallback,
    loading,
    newElementButton,
    onPageChange,
    onPageSizeChange,
    openDeleteModal,
    removeTacticLoading,
    rowCountState,
    rowId,
    setOpenDeleteModal,
    tacticListColumnConfig,
    tactics,
  } = useTacticsList();
  const { t } = useTranslation();

  return (
    <>
      <GridList
        getRowId={ (row) => row._id }
        loading={ loading }
        columnConfig={ tacticListColumnConfig }
        rows={ tactics }
        onPageChange={ onPageChange }
        onPageSizeChange={ onPageSizeChange }
        rowCountState={ rowCountState }
        newElementButton={ newElementButton }
        getRowClassName={ (params) => (params.row.isKeyPerformanceIndicator ? 'kpi-theme' : '') }
        disableColumnMenu={ true }
        sx={ kpiStyle }
      />
      {
        openDeleteModal && (
          <ConfirmationDialog
            dialogTitle={ t('maya_tactic_modal_remove') }
            onConfirm={
              () => {
                deleteTacticCallback(rowId);
              }
            }
            onConfirmLabel={ removeTacticLoading ? <Loader center size={ 1 } color={ YodaColors.inherit } /> : t('actions_confirm_label') }
            onDismiss={ () => setOpenDeleteModal(false) }
            onDismissLabel={ t('actions_cancel_label') }
          >
          </ConfirmationDialog>
        )
      }
    </>
  );
};

export default TacticsList;

