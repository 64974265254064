import { transformInputDateUTC } from 'basics/utils/dates/dates.utils';
import { CustomerEngagementPlanning, CustomerInitiative } from 'generated/maya.types';
import { isCustomerInitiative } from 'modules/maya/basics/types/customerInitiative.types';
import { formatUTCDate } from 'modules/maya/basics/utils/date.utils';
import { configurationStepFields } from 'modules/maya/groups/CepFormConfigurationGroup/CepFormConfigurationGroup.constants';
import { goalStepFields } from 'modules/maya/groups/CepFormGoalGroup/CepFormGoalGroup.constants';
import { YodaFieldValue } from 'yoda-ui/yodaForm/yodaForm.types';

export const cepToBE = (data: YodaFieldValue) => {
  return {
    title: data[configurationStepFields.title],
    description: data[configurationStepFields.description],
    customerInitiative: data[configurationStepFields.customerInitiative],
    therapeuticArea: data[configurationStepFields.therapeuticArea],
    product: data[configurationStepFields.product],
    biogenFunction: data[configurationStepFields.biogenFunction],
    keyMessages: data[configurationStepFields.keyMessages],
    ...transformInputDateUTC(data[configurationStepFields.date]),
    kpi: data[configurationStepFields.kpi],
    customerType: data[configurationStepFields.customerType],
    specialty: data[configurationStepFields.specialty],
    targetEngagementGoal: parseInt(data[goalStepFields.targetEngagementGoal], 10),
    npsGoal: parseInt(data[goalStepFields.npsGoal], 10),
    reachGoal: data[goalStepFields.reachGoal],
  };
};

export const cepToForm = (
  cep: Partial<CustomerEngagementPlanning> | undefined | null,
  customerInitiative?: CustomerInitiative | undefined,
) => {
  let customerInitiativeDefaultValue = isCustomerInitiative(cep?.customerInitiative) ? cep?.customerInitiative._id : '';
  if (!customerInitiativeDefaultValue) {
    customerInitiativeDefaultValue = customerInitiative?._id || '';
  }

  const therapeuticAreaDefaultValue = cep?.therapeuticArea || customerInitiative?.therapeuticArea || '';
  const productDefaultValue = cep?.product || customerInitiative?.product || '';
  const biogenFunctionDefaultValue = cep?.biogenFunction || customerInitiative?.functionalOwner || '';
  const dateDefaultValue = cep?.dateStart
    ? formatUTCDate(cep?.dateStart, cep?.dateEnd)
    : formatUTCDate(customerInitiative?.dateStart);

  return {
    title: cep?.title || '',
    description: cep?.description || '',
    customerInitiative: customerInitiativeDefaultValue,
    therapeuticArea: therapeuticAreaDefaultValue,
    product: productDefaultValue,
    biogenFunction: biogenFunctionDefaultValue,
    keyMessages: cep?.keyMessages || '',
    date: dateDefaultValue,
    kpi: cep?.kpi || '',
    customerType: cep?.customerType || [],
    specialty: cep?.specialty || [],
    targetEngagementGoal: cep?.targetEngagementGoal?.toString() || '',
    npsGoal: cep?.npsGoal?.toString() || '',
    reachGoal: cep?.reachGoal || 0,
  };
};

