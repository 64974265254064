import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Heading from 'modules/maya/components//Heading';
import CepForm from 'modules/maya/components/CepForm/CepForm';
import MayaLayout from 'modules/maya/components/MayaLayout';
import useCepCreate from 'modules/maya/routes/CepCreate/CepCreate.hook';

const CepCreate: FC = () => {
  const { t } = useTranslation();
  const {
    submitCallback,
  } = useCepCreate();

  return (
    <MayaLayout>
      <Heading>
        { t('maya_create_cep_form_title') }
      </Heading>

      <CepForm submitCallback={ submitCallback } isTemplate={ false } />
    </MayaLayout>
  );
};

export default CepCreate;
