import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useCepDuplicate from './CepDuplicate.hook';
import { VeevaSyncStatus } from 'modules/maya/basics/enums/maya.enums';
import Heading from 'modules/maya/components//Heading';
import CepForm from 'modules/maya/components/CepForm/CepForm';
import MayaLayout from 'modules/maya/components/MayaLayout';
import VeevaStatusBar from 'modules/maya/components/VeevaStatusBar/VeevaStatusBar';

const CepDuplicate: FC = () => {
  const { t } = useTranslation();
  const {
    submitCallback,
    customerEngagementPlanningStateValue,
  } = useCepDuplicate();

  return (
    <MayaLayout>
      <Heading>
        { t('maya_duplicate_cep_form_title') }
        {
          customerEngagementPlanningStateValue.cep?._id
            ? (<VeevaStatusBar
              lastVeevaSync={ customerEngagementPlanningStateValue.cep?.lastVeevaSync }
              lastVeevaSyncErrors={ customerEngagementPlanningStateValue.cep?.globalCepSyncStatus?.errors }
              veevaSyncStatus={ customerEngagementPlanningStateValue.cep?.globalCepSyncStatus?.syncStatus as VeevaSyncStatus }
            />)
            : null
        }
      </Heading>

      <CepForm submitCallback={ submitCallback } isTemplate={ false }/>
    </MayaLayout>
  );
};

export default CepDuplicate;
