import { FC } from 'react';
import useCiDashboardList from './CiDashboardList.hook';
import { CountWidgetPropsType } from 'modules/maya/components/CountWidgets/CountWidget.types';
import GridList from 'modules/maya/components/GridList/GridList';

const CiDashboardList: FC<CountWidgetPropsType> = ({ filters }) => {
  const {
    customerInitiativeListColumnConfig,
    cis,
    loading,
    onPageChange,
    onPageSizeChange,
    rowCountState,
    onCellClick,
  } = useCiDashboardList(filters);

  return (
    <GridList
      getRowId={ (row) => row._id }
      loading={ loading }
      columnConfig={ customerInitiativeListColumnConfig }
      rows={ cis }
      onPageChange={ onPageChange }
      onPageSizeChange={ onPageSizeChange }
      rowCountState={ rowCountState }
      disableColumnMenu={ true }
      onCellClick={ onCellClick }
    />
  );
};

export default CiDashboardList;
