import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import GridListAction from 'modules/maya/components/GridListAction/GridListAction';

const useCepTrashedListConfig = (
  restoreCepCallback: (cepId: string) => void,
) => {
  const { t } = useTranslation();

  const cepTitleFieldConfig = {
    label: t('maya_cep_trashed_title_label'),
    name: 'name',
    placeholder: t('maya_cep_trashed_title_placeholder'),
    defaultValue: '',
    required: false,
  };

  const cepListColumnConfig: GridColDef[] = [
    {
      field: 'title',
      headerName: t('maya_cep_list_title_header_label'),
      flex: 0.3,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'formattedCepId',
      headerName: t('maya_cep_list_cep_id_header_label'),
      flex: 0.2,
      sortable: false,
    },
    {
      field: 'action',
      headerName: '',
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params: GridRenderCellParams) => {
        const actions = [
          {
            label: t('actions_restore_cep_label'),
            callback: () => (restoreCepCallback(params.row._id)),
          },
        ];

        return (
          <GridListAction
            actions={ actions }
          />
        );
      },
      align: 'right',
      headerAlign: 'right',
      flex: 0.05,
    },
  ];

  return {
    cepListColumnConfig,
    cepTitleFieldConfig,
  };
};

export default useCepTrashedListConfig;
