import { useMemo } from 'react';
import { Tactic } from 'generated/maya.types';
import { TacticStatus } from 'modules/maya/basics/enums/maya.enums';
import useGetAllTacticsQuery from 'modules/maya/graphql/queries/getAllTactics';

const useTacticOptions = (
  cepId: string | null,
  excludedTacticsId: string[] = [],
  statuses: TacticStatus[] = [TacticStatus.DRAFT, TacticStatus.APPROVED],
) => {
  const queryFilters = useMemo(
    () => ({
      cep: cepId,
      status: statuses,
    }),
    [cepId, statuses],
  );

  const { data: tactics } = useGetAllTacticsQuery(undefined, queryFilters);
  return tactics
    .filter((element: Tactic) => !excludedTacticsId.includes(element._id))
    .map((element: Tactic) => {
      return {
        id: element._id,
        label: element.title,
        channel: element.channel,
        formattedId: element.formattedTacticId,
      };
    });
};

export default useTacticOptions;
