import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

import { BiogenProduct } from 'basics/enums/biogen.enums';
import GridListAction from 'modules/maya/components/GridListAction/GridListAction';

const useCiTemplateListConfig = (
  editCiTemplateCallback: (ciId: string) => void,
  duplicateCiTemplateCallback: (ciId: string) => void,
  addChildCallback: (ciId: string) => void,
  openModalCallback: (ciId: string) => void,
) => {
  const { t } = useTranslation();
  const flags = useFlags();

  const ciTitleFieldConfig = {
    label: t('maya_ci_tpl_title_label'),
    name: 'name',
    placeholder: t('maya_ci_tpl_title_placeholder'),
    defaultValue: '',
    required: false,
  };

  const customerInitiativeListColumnConfig: GridColDef[] = [
    {
      field: 'title',
      headerName: t('maya_ci_tpl_list_initiative_name_header_label'),
      flex: 0.6,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'product',
      headerName: t('maya_customer_initiative_list_product_header_label'),
      valueGetter: (params: GridValueFormatterParams) => {
        const productIndex = Object.values(BiogenProduct).indexOf(params.value as BiogenProduct);
        return Object.keys(BiogenProduct)[productIndex] || '';
      },
      flex: 0.3,
      sortable: false,
    },
    {
      field: 'action',
      headerName: '',
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params: GridRenderCellParams) => {
        const actions = [
          {
            label: t('actions_edit_label'),
            callback: () => { editCiTemplateCallback(params.row._id); },
          },
          {
            label: t('actions_ci_from_tpl_label'),
            callback: () => { duplicateCiTemplateCallback(params.row._id); },
          },
          {
            label: t('maya_customer_initiative_list_add_child_cep'),
            callback: () => { addChildCallback(params.row._id); },
          },
        ];

        if (flags.mayaBiogenlinc3810EnableCiDeleting) {
          actions.splice(1, 0, {
            label: t('maya_action_delete_ci_label'),
            callback: () => {
              openModalCallback(params.row._id);
            },
          });
        }

        return (
          <GridListAction
            actions={ actions }
          />
        );
      },
      align: 'right',
      headerAlign: 'right',
      flex: 0.1,
      sortable: false,
    },
  ];

  return { customerInitiativeListColumnConfig, ciTitleFieldConfig };
};

export default useCiTemplateListConfig;
