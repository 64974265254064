import { SelectOption } from 'basics/options/options.types';
import { Specialities } from 'modules/maya/basics/enums/maya.enums';

const SpecialitiesOptions: SelectOption[] = [
  { id: Specialities.childNeurology, label: 'Child Neurology' },
  { id: Specialities.dermatology, label: 'Dermatology' },
  { id: Specialities.gastroenterology, label: 'Gastroenterology' },
  { id: Specialities.generalPractice, label: 'General Practice' },
  { id: Specialities.generalSurgery, label: 'General Surgery' },
  { id: Specialities.geriatricsGerontology, label: 'Geriatrics/Gerontology' },
  { id: Specialities.hospitalPharmacy, label: 'Hospital Pharmacy' },
  { id: Specialities.internalMedicine, label: 'Internal Medicine' },
  { id: Specialities.neurology, label: 'Neurology' },
  { id: Specialities.ophthalmology, label: 'Ophthalmology' },
  { id: Specialities.other, label: 'Other' },
  { id: Specialities.pediatrics, label: 'Pediatrics' },
  { id: Specialities.physicalMedicineRehabilitation, label: 'Physical Medicine & Rehabilitation' },
  { id: Specialities.physicalTherapy, label: 'Physical Therapy' },
  { id: Specialities.psychiatry, label: 'Psychiatry' },
  { id: Specialities.radiology, label: 'Radiology' },
  { id: Specialities.rheumatology, label: 'Rheumatology' },
];

export default SpecialitiesOptions;
