import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { MutationReturnType } from 'modules/maya/basics/types/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const syncTargetsToVeevaMutationCall = gql`
  mutation syncTargetsToVeeva($cepId: String!) {
    syncTargetsToVeeva(cepId: $cepId)
  }
`;

type ResultType = {
  syncTargetsToVeeva: boolean;
};

type UseSyncTargetsToVeevaMutation = [
  (cepId: string) => Promise<MutationReturnType<boolean>>,
  MutationResult<ResultType>,
];

export const useSyncTargetsToVeevaMutation = (): UseSyncTargetsToVeevaMutation => {
  const [syncTargetsToVeeva, state] = useMutation<ResultType>(syncTargetsToVeevaMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  const executeMutation = useCallback(async (cepId) => {
    try {
      const { data } = await syncTargetsToVeeva({
        variables: {
          cepId,
        },
      });

      return { data: !!data?.syncTargetsToVeeva, error: null };
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'syncTargetsToVeeva.ts - useSyncTargetsToVeevaMutation',
        });

        return { data: null, error: customContext.message as string };
      }

      return { data: null, error: null };
    }
  }, [syncTargetsToVeeva]);

  return [executeMutation, state];
};
