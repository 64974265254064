import { GridCellParams } from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCepDashboardListConfig from './CepDashboardList.config';
import { mayaCepEditRoute } from 'basics/constants/routes.constants';
import { CustomerEngagementPlanning, DashboardFilter } from 'generated/maya.types';
import { PAGINATION_PAGE_SIZE } from 'modules/maya/basics/constants/common.constant';
import useGetAllCepQuery from 'modules/maya/graphql/queries/getAllCeps';

const useCepDashboardList = (filters?: DashboardFilter) => {
  const [customerInitiativeFilter] = useState<(string | number)[]>([]);
  const [statusFilter] = useState<(string | number)[]>([]);
  const [ceps, setCeps] = useState<CustomerEngagementPlanning[]>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(0);

  const navigate = useNavigate();

  const queryOptions = useMemo(
    () => ({
      limit: pageSize,
      offset: page * pageSize,
    }),
    [page, pageSize],
  );

  let queryFilters;
  if (filters) {
    queryFilters = { ...filters, tacticStatus: undefined };
  }

  const { data: cepData, loading: cepLoading, count } = useGetAllCepQuery(queryFilters, queryOptions);

  useEffect(() => {
    if (filters) {
      setRowCountState((prevRowCountState) => (count !== undefined ? count : prevRowCountState));
    }
  }, [count, filters, setRowCountState]);

  useEffect(() => {
    if (!cepLoading && filters) {
      setCeps(cepData);
    }
  }, [cepData, cepLoading, filters]);

  const {
    cepListColumnConfig,
  } = useCepDashboardListConfig();

  const getFilteredRows = useCallback(() => {
    let filteredRows = ceps;

    if (customerInitiativeFilter.length > 0) {
      filteredRows = cepData.filter((value) => {
        if (typeof value.customerInitiative === 'string') {
          return value.customerInitiative;
        }

        return customerInitiativeFilter.indexOf(value.customerInitiative._id) !== -1;
      });
    }

    if (statusFilter.length > 0) {
      filteredRows = filteredRows.filter((value) => {
        return statusFilter.indexOf(value.status) !== -1;
      });
    }

    return filteredRows;
  }, [ceps, customerInitiativeFilter, statusFilter, cepData]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const onCellClick = (params: GridCellParams) => {
    navigate(`${mayaCepEditRoute}?id=${params.row._id}`);
  };

  return {
    cepListColumnConfig,
    customerInitiativeFilter,
    getFilteredRows,
    statusFilter,
    cepData,
    cepLoading,
    onPageChange,
    onPageSizeChange,
    rowCountState,
    onCellClick,
  };
};

export default useCepDashboardList;
