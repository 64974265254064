import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import useCepFormConfigurationGroupSchema from './CepFormConfigurationGroup.schema';
import { CepFormConfigurationGroupConfigType } from './CepFormConfigurationGroup.types';
import useProductOptions from 'basics/options/product.options';
import useTherapeuticAreaOptions from 'basics/options/therapeuticArea.options';
import { CurrentUser } from 'basics/types/user.types';
import { CustomerInitiative } from 'generated/maya.types';
import { CepStatus } from 'modules/maya/basics/enums/maya.enums';
import biogenFunctionOptions from 'modules/maya/basics/options/biogenFunction.options';
import useCustomerInitiativeTypeOptions from 'modules/maya/basics/options/customerInitiative.options';
import customerTypeOptions from 'modules/maya/basics/options/customerType.options';
import SpecialitiesOptions from 'modules/maya/basics/options/specialty.options';
import { cepToForm } from 'modules/maya/basics/transformers/Cep.transformer';
import { CepStateType } from 'modules/maya/basics/types/maya.types';
import { configurationStepFields } from 'modules/maya/groups/CepFormConfigurationGroup/CepFormConfigurationGroup.constants';
import cepState from 'modules/maya/states/cep.states';

const useCepFormConfigurationGroupConfig = (
  customerInitiative: CustomerInitiative | undefined,
  user: CurrentUser | null | undefined,
  therapeuticArea: string | null,
  startDate: Date | null,
  isTemplate: boolean,
) => {
  const { t } = useTranslation();
  const cepFormConfigurationGroupSchema = useCepFormConfigurationGroupSchema();

  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const defaultFormValue = cepToForm(cepStateValue.cep, customerInitiative);

  const currentTA = therapeuticArea ?? defaultFormValue?.therapeuticArea ?? '';
  const isCepApproved = cepStateValue.cep?.status === CepStatus.APPROVED;

  const cepFormConfigurationGroup: CepFormConfigurationGroupConfigType = {
    title: {
      label: t('maya_cep_step_configuration_name_label'),
      name: configurationStepFields.title,
      placeholder: t('maya_cep_step_configuration_name_label'),
      required: true,
      defaultValue: defaultFormValue.title,
      validation: cepFormConfigurationGroupSchema.title.trim(),
      disabled: isCepApproved,
    },
    date: {
      startText: t('maya_cep_step_configuration_date_start_label'),
      endText: t('maya_cep_step_configuration_date_end_label'),
      name: configurationStepFields.date,
      disablePast: false,
      validation: cepFormConfigurationGroupSchema.date,
      startRequired: true,
      endRequired: true,
      defaultValue: defaultFormValue.date,
      disabled: isCepApproved,
    },
    ciDateStart: {
      label: '',
      name: configurationStepFields.ciDateStart,
      placeholder: '',
      required: true,
      defaultValue: customerInitiative?.dateStart,
      type: 'hidden',
    },
    ciDateEnd: {
      label: '',
      name: configurationStepFields.ciDateEnd,
      placeholder: '',
      required: true,
      defaultValue: customerInitiative?.dateEnd,
      type: 'hidden',
    },
    customerInitiative: {
      label: t('maya_cep_step_configuration_customer_initiative_label'),
      name: configurationStepFields.customerInitiative,
      placeholder: t('maya_cep_step_configuration_customer_initiative_label'),
      options: useCustomerInitiativeTypeOptions(
        {
          isTemplate,
          year: startDate?.getFullYear(),
          countries: user?.country ? [user.country] : undefined,
        },
      ),
      required: true,
      defaultValue: defaultFormValue.customerInitiative,
      validation: cepFormConfigurationGroupSchema.customerInitiative,
      disabled: isCepApproved,
    },
    therapeuticArea: {
      label: t('maya_cep_step_configuration_therapeutic_area_label'),
      name: configurationStepFields.therapeuticArea,
      placeholder: t('maya_cep_step_configuration_therapeutic_area_label'),
      options: useTherapeuticAreaOptions(),
      required: true,
      defaultValue: defaultFormValue.therapeuticArea || '',
      validation: cepFormConfigurationGroupSchema.therapeuticArea,
      disabled: isCepApproved,
    },
    product: {
      label: t('maya_cep_step_configuration_product_label'),
      name: configurationStepFields.product,
      placeholder: t('maya_cep_step_configuration_product_label'),
      options: useProductOptions(currentTA),
      required: true,
      defaultValue: defaultFormValue.product || '',
      validation: cepFormConfigurationGroupSchema.product,
      disabled: isCepApproved,
    },
    biogenFunction: {
      label: t('maya_cep_step_configuration_function_label'),
      name: configurationStepFields.biogenFunction,
      placeholder: t('maya_cep_step_configuration_function_placeholder'),
      options: biogenFunctionOptions,
      required: true,
      defaultValue: defaultFormValue.biogenFunction,
      validation: cepFormConfigurationGroupSchema.biogenFunction,
      disabled: isCepApproved,
    },
    kpi: {
      label: t('maya_cep_step_configuration_kpi_label'),
      name: configurationStepFields.kpi,
      placeholder: t('maya_cep_step_configuration_kpi_label'),
      required: true,
      defaultValue: defaultFormValue.kpi,
      validation: cepFormConfigurationGroupSchema.kpi.trim(),
      disabled: isCepApproved,
    },
    description: {
      label: t('maya_cep_step_configuration_description_label'),
      name: configurationStepFields.description,
      placeholder: t('maya_cep_step_configuration_description_placeholder'),
      required: false,
      multiline: true,
      rows: 4,
      defaultValue: defaultFormValue.description,
      validation: cepFormConfigurationGroupSchema.description,
      disabled: isCepApproved,
    },
    customerType: {
      label: t('maya_cep_step_configuration_customer_type_label'),
      name: configurationStepFields.customerType,
      placeholder: t('maya_cep_step_configuration_customer_type_label'),
      options: customerTypeOptions,
      required: true,
      defaultValue: defaultFormValue.customerType,
      validation: cepFormConfigurationGroupSchema.customerType,
      disabled: isCepApproved,
    },
    specialty: {
      label: t('maya_cep_step_configuration_specialty_label'),
      name: configurationStepFields.specialty,
      placeholder: t('maya_cep_step_configuration_specialty_label'),
      options: SpecialitiesOptions,
      defaultValue: defaultFormValue.specialty,
      required: true,
      validation: cepFormConfigurationGroupSchema.specialties,
      disabled: isCepApproved,
    },
  };

  return {
    cepFormConfigurationGroup,
  };
};

export default useCepFormConfigurationGroupConfig;
