import { useSnapshot } from 'valtio';
import useCepFormGoalGroupConfig
  from './CepFormGoalGroup.config';
import { CepStateType } from 'modules/maya/basics/types/maya.types';
import useGetCepEngagementQuery from 'modules/maya/graphql/queries/getCepEngagement';
import useGetCepReachQuery from 'modules/maya/graphql/queries/getCepReach';
import cepState from 'modules/maya/states/cep.states';

const useCepFormGoalGroup = () => {
  const cepStateValue = useSnapshot(cepState) as CepStateType;

  const { cepEngagement, loading: engagementLoading } = useGetCepEngagementQuery(cepStateValue.cep?._id);
  const { cepReach, loading: reachLoading } = useGetCepReachQuery(cepStateValue.cep?._id);

  const { cepFormGoalGroup } = useCepFormGoalGroupConfig(cepEngagement, cepReach);

  return {
    cepFormGoalGroup,
    engagementLoading,
    reachLoading,
  };
};

export default useCepFormGoalGroup;
