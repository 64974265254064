import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useGetStrategicImperativeByIdQuery from 'modules/maya/graphql/queries/getStrategicImperativeById';

const useStrategicImperativeView = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const strategicImperative = useGetStrategicImperativeByIdQuery(urlParams.get('id')).data;

  useEffect(() => {
    if (strategicImperative) {
      setLoading(false);
    }
  }, [strategicImperative, setLoading]);

  return {
    loading,
    navigate,
    strategicImperative,
  };
};

export default useStrategicImperativeView;
