import { useEffect } from 'react';
import { DashboardFilter } from 'generated/maya.types';
import useGetTargetsEngagementQuery from 'modules/maya/graphql/queries/getTargetsEngagement';

const useTargetsEngagementWidget = (filters?: DashboardFilter) => {
  const { getTargetsEngagementQuery, targetsEngagement, loading, error } = useGetTargetsEngagementQuery();

  useEffect(() => {
    if (filters) {
      getTargetsEngagementQuery({ variables: { filters } });
    }
  }, [filters, getTargetsEngagementQuery]);

  return {
    value: targetsEngagement,
    loading,
    error,
  };
};

export default useTargetsEngagementWidget;
