import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import ActionButton from 'components/ActionButton';
import ContentCard from 'components/ContentCard';
import { TargetListMode } from 'modules/maya/basics/enums/maya.enums';
import DynamicTargetListForm from 'modules/maya/components/DynamicTargetListForm/DynamicTargetListForm';
import GridList from 'modules/maya/components/GridList/GridList';
import Heading from 'modules/maya/components/Heading';
import { LoadingDialog } from 'modules/maya/components/LoadingDialog/LoadingDialog';
import UploadTargetListForm from 'modules/maya/components/UploadTargetListForm/UploadTargetListForm';
import useCepFormTargetListGroup from 'modules/maya/groups/CepFormTargetListGroup/CepFormTargetListGroup.hook';
import {
  TargetsButtonStyle,
  targetListSelectStyle,
} from 'modules/maya/groups/CepFormTargetListGroup/CepFormTargetListGroup.styles';
import { CepFormTargetListGroupType } from 'modules/maya/groups/CepFormTargetListGroup/CepFormTargetListGroup.types';
import TargetListExport from 'modules/maya/groups/CepFormTargetListGroup/TargetListExport/TargetListExport';
import Box from 'yoda-ui/components/Box';
import ConfirmationDialog from 'yoda-ui/components/Dialogs/ConfirmationDialog';
import RadioGroup from 'yoda-ui/components/Form/RadioGroup';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const CepFormTargetListGroup: FC<CepFormTargetListGroupType> = ({ syncTargetsLoading }) => {
  const {
    cepFormTargetListGroupConfig,
    data,
    handleRemoveTargets,
    loading,
    onPageChange,
    onPageSizeChange,
    open,
    openModalButtonConfig,
    rowCountState,
    setLoading,
    setOpen,
    syncTargetsButtonConfig,
    t,
    targetListConfig,
    targetListMode,
    updateTargetList,
  } = useCepFormTargetListGroup();

  const flags = useFlags();

  return (
    <>
      <Box>
        <ContentCard>
          <Heading level={ 2 }>
            { t('maya_targetlist_select_title') }
          </Heading>
          <Box>
            <RadioGroup { ...cepFormTargetListGroupConfig.selectTargetList } sx={ targetListSelectStyle } />
          </Box>
        </ContentCard>
      </Box>
      <Box marginTop={ YodaSpacing.large }>
        {
          targetListMode === TargetListMode.dynamic
            ? <DynamicTargetListForm
              updateTargetList={ updateTargetList }
              loading={ loading }
              setLoading={ setLoading }
            />
            : <UploadTargetListForm
              updateTargetList={ updateTargetList }
              loading={ loading }
              setLoading={ setLoading }
            />
        }
        <ContentCard sx={ { paddingBottom: YodaSpacing.medium } }>
          <GridList
            getRowId={ (row) => row._id }
            loading={ loading }
            columnConfig={ targetListConfig }
            rows={ data }
            onPageChange={ onPageChange }
            onPageSizeChange={ onPageSizeChange }
            rowCountState={ rowCountState }
            disableColumnMenu={ true }
            hideFooterPagination={ rowCountState === 0 }
          />
          {
            open && (
              <ConfirmationDialog
                dialogTitle={ t('maya_targetlist_modal_remove') }
                onConfirm={
                  () => {
                    handleRemoveTargets();
                  }
                }
                onConfirmLabel={ t('actions_confirm_label') }
                onDismiss={ () => setOpen(false) }
                onDismissLabel={ t('actions_cancel_label') }
              >
              </ConfirmationDialog>
            )
          }
          {
            syncTargetsLoading && (
              <LoadingDialog text={ t('maya_cep_targets_loading_message') } />
            )
          }
          <Box display="flex" justifyContent="space-between">
            <Box marginTop={ YodaSpacing.large }>
              {
                flags.mayaBiogenlinc4691EnableTargetListExport
                  ? <TargetListExport /> : null
              }
            </Box>
            <Box sx={ TargetsButtonStyle }>
              <ActionButton { ...syncTargetsButtonConfig } />
              <ActionButton { ...openModalButtonConfig } />
            </Box>
          </Box>
        </ContentCard>
      </Box>
    </>

  );
};

export default CepFormTargetListGroup;
