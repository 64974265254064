import { useEffect } from 'react';
import { DashboardFilter } from 'generated/maya.types';
import useGetDashboardCIQuery from 'modules/maya/graphql/queries/getDashboardCI';

const useCountCiWidget = (filters?: DashboardFilter) => {
  const { getDashboardCIQuery, ciCount, loading, error } = useGetDashboardCIQuery();

  useEffect(() => {
    if (filters) {
      getDashboardCIQuery({ variables: { filters, limit: 5000, offset: 0 } });
    }
  }, [filters, getDashboardCIQuery]);

  return {
    value: ciCount,
    loading,
    error,
  };
};

export default useCountCiWidget;
