import { CepSteps } from 'modules/maya/basics/enums/maya.enums';
import { useYodaForm } from 'yoda-ui/yodaForm';

const useGoalStep = () => {
  const { useWatchStep } = useYodaForm();
  const stepState = useWatchStep(CepSteps.goalStep);

  return {
    stepIsValid: stepState ? stepState.isValid : false,
  };
};

export default useGoalStep;
