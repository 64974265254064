import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useCepEdit from './CepEdit.hook';
import { VeevaSyncStatus } from 'modules/maya/basics/enums/maya.enums';
import Heading from 'modules/maya/components//Heading';
import CepForm from 'modules/maya/components/CepForm/CepForm';
import MayaLayout from 'modules/maya/components/MayaLayout';
import VeevaStatusBar from 'modules/maya/components/VeevaStatusBar/VeevaStatusBar';

const CepEdit: FC = () => {
  const { t } = useTranslation();
  const {
    cepStateValue,
    submitCallback,
  } = useCepEdit();

  return (
    <MayaLayout>
      <Heading>
        { t('maya_edit_cep_form_title') }
        {
          cepStateValue
            ? (<VeevaStatusBar
              lastVeevaSync={ cepStateValue.cep?.lastVeevaSync }
              lastVeevaSyncErrors={ cepStateValue.cep?.globalCepSyncStatus?.errors }
              veevaSyncStatus={ cepStateValue.cep?.globalCepSyncStatus?.syncStatus as VeevaSyncStatus }
            />)
            : null
        }
      </Heading>

      <CepForm submitCallback={ submitCallback } isTemplate={ false }/>
    </MayaLayout>
  );
};

export default CepEdit;
