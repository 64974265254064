import useCepFormActions from './CepForm.actions.hook';
import useCepFormButtons from './CepFormButtons.hook';
import useCepFormStepper from './CepFormStepper.hook';
import { CepFormSubmitCallbackType } from 'modules/maya/components/CepForm/CepForm.types';
import { useYodaCreateForm } from 'yoda-ui/yodaForm';

const useCepForm = (submitCallback: CepFormSubmitCallbackType, isTemplate: boolean) => {
  const yodaFormMethods = useYodaCreateForm();
  const customerEngagementPlanningActions = useCepFormActions(yodaFormMethods, submitCallback, isTemplate);
  const buttonsConfig = useCepFormButtons(yodaFormMethods, customerEngagementPlanningActions, isTemplate);
  const stepsList = useCepFormStepper(yodaFormMethods.useWatchStep);
  const { handleChangeStep, stepIndexer, syncTargetsLoading } = customerEngagementPlanningActions;

  return {
    handleChangeStep,
    yodaFormMethods,
    stepIndexer,
    buttonsConfig,
    stepsList,
    syncTargetsLoading,
  };
};

export default useCepForm;
