import { FC } from 'react';
import ContentCard from 'components/ContentCard';
import { FormLayoutContainer } from 'components/FormLayout';
import useCepFormConfigurationGroup from 'modules/maya/groups/CepFormConfigurationGroup/CepFormConfigurationGroup.hook';
import { CepFormConfigurationGroupPropsType } from 'modules/maya/groups/CepFormConfigurationGroup/CepFormConfigurationGroup.types';
import Box from 'yoda-ui/components/Box';
import DateRangePicker from 'yoda-ui/components/Form/DateRangePicker';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import MultiSelectYoda from 'yoda-ui/components/Form/SelectYoda/MultiSelectYoda';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import { fromUnitsToRem, YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

const CepFormConfigurationGroup: FC<CepFormConfigurationGroupPropsType> = ({ isTemplate }) => {
  const {
    cepFormConfigurationGroup,
    handleCustomerInitiativeChange,
    handleChangeTA,
    handleChangeProduct,
    handleDateChange,
  } = useCepFormConfigurationGroup(isTemplate);

  return (
    <>
      <ContentCard sx={ { marginBottom: fromUnitsToRem(YodaSpacing.large) } }>
        <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } flexDirection="column" >
          <Box>
            <TextYoda { ...cepFormConfigurationGroup.title } />
          </Box>
          <Box paddingTop={ YodaSpacing.large }>
            <SelectYoda
              { ...cepFormConfigurationGroup.customerInitiative }
              onChange={ handleCustomerInitiativeChange }
            />
          </Box>
          <Box>
            <TextYoda { ...cepFormConfigurationGroup.ciDateStart } display={ false }/>
          </Box>
          <Box>
            <TextYoda { ...cepFormConfigurationGroup.ciDateEnd } display={ false } />
          </Box>
          <Box paddingTop={ YodaSpacing.large }>
            <DateRangePicker { ...cepFormConfigurationGroup.date } onChange={ handleDateChange } />
          </Box>
        </FormLayoutContainer>
      </ContentCard>
      <ContentCard sx={ { marginBottom: fromUnitsToRem(YodaSpacing.large) } }>
        <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } flexDirection="column" >
          <Box>
            <SelectYoda { ...cepFormConfigurationGroup.therapeuticArea } onChange={ handleChangeTA } />
          </Box>
          <Box paddingTop={ YodaSpacing.large }>
            <SelectYoda { ...cepFormConfigurationGroup.product } onChange={ handleChangeProduct } />
          </Box>
          <Box paddingTop={ YodaSpacing.large }>
            <SelectYoda { ...cepFormConfigurationGroup.biogenFunction } />
          </Box>
          <Box paddingTop={ YodaSpacing.large } >
            <TextYoda { ...cepFormConfigurationGroup.kpi } />
          </Box>
        </FormLayoutContainer>
      </ContentCard>
      <ContentCard sx={ { marginBottom: fromUnitsToRem(YodaSpacing.large) } }>
        <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } flexDirection="column" >
          { <TextYoda { ...cepFormConfigurationGroup.description } /> }
        </FormLayoutContainer>
      </ContentCard>
      <ContentCard sx={ { marginBottom: fromUnitsToRem(YodaSpacing.large) } }>
        <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } flexDirection="column" >
          <Box>
            <MultiSelectYoda { ...cepFormConfigurationGroup.customerType } />
          </Box>
          <Box paddingTop={ YodaSpacing.large }>
            <MultiSelectYoda { ...cepFormConfigurationGroup.specialty } />
          </Box>
        </FormLayoutContainer>
      </ContentCard>
    </>
  );
};

export default CepFormConfigurationGroup;
