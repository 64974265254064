import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VeevaSyncStatus } from 'modules/maya/basics/enums/maya.enums';

const useVeevaStatusBar = (
  lastVeevaSync: Date | null,
  veevaSyncStatus: VeevaSyncStatus,
) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const lastSyncDate = lastVeevaSync ? new Date(lastVeevaSync).toLocaleString() : null;

  const veevaStatusMap = {
    [VeevaSyncStatus.SYNC as string]: {
      status: t('maya_veeva_sync_status_synchronized'),
      alertStatus: 'success',
    },
    [VeevaSyncStatus.ERROR as string]: {
      status: t('maya_veeva_sync_status_error'),
      alertStatus: 'error',
    },
    [VeevaSyncStatus.PENDING as string]: {
      status: t('maya_veeva_sync_status_pending'),
      alertStatus: 'warning',
    },
  };

  return {
    status: veevaStatusMap[veevaSyncStatus].status,
    alertStatus: veevaStatusMap[veevaSyncStatus].alertStatus,
    lastSyncDate,
    open,
    setOpen,
  };
};

export default useVeevaStatusBar;
